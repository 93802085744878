.partnerFormCard {
    --form-card-width: calc(100% + var(--ds-spacing-super));
    --form-card-min-height: 41.3rem;
    --form-card-border-radius: 0;
    --form-card-padding: var(--ds-spacing-big) var(--ds-spacing-medium);
    --input-wraper-direction: column;
    --input-height: 3.75rem;
    --input-error-colour: rgb(235, 87, 87);
    --input-border-colour: rgb(54, 70, 101, 0.6);
    --input-label-colour: rgb(12, 18, 39);
    --text-area-height: 8rem;
    --font-size: var(--ds-font-size-large);
    --error-icon-size: 1.31rem;
    --submit-icon-size: 3rem;
    --submit-success-colour: rgb(93, 240, 250);

    @media (--ds-mq-phablet) {
        --form-card-width: 32rem;
        --form-card-min-width: 29.3rem;
        --form-card-padding: var(--ds-spacing-big);
        --form-card-border-radius: var(--ds-border-radius-medium);
        --input-wraper-direction: row;
        --font-size: var(--ds-font-size-big);
    }

    width: var(--form-card-width);
    min-width: var(--form-card-min-width);
    min-height: var(--form-card-min-height);
    background-color: var(--ds-colour-white);
    border-radius: var(--form-card-border-radius);
    margin-left: var(--form-card-margin-left);
    color: var(--ds-colour-panel);
    padding: var(--form-card-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: var(--ds-zindex-levitate);
}

.formHeader {
    font-size: var(--font-size);
    font-weight: var(--ds-font-weight-regular);
}

.formWrapper {
    margin-top: var(--ds-spacing-big);
    width: 100%;
}

.displayDirection {
    display: flex;
    flex-direction: var(--input-wraper-direction);
    justify-content: space-between;
}

.inputWrapper {
    position: relative;
    color: var(--input-label-colour);
    margin-bottom: var(--ds-spacing-huge);
    width: 100%;

    &:first-child {
        margin-right: var(--ds-spacing-medium);
    }
}

.inputLabel {
    font-size: var(--ds-font-size-small);
    line-height: var(--ds-line-height-generous);
}

.input {
    display: flex;
    height: var(--input-height);
    min-width: 100%;
    width: 100%;
    border-radius: var(--ds-border-radius-medium);
    border: var(--ds-border-width-thin) solid var(--input-border-colour);
    margin-top: var(--ds-spacing-tiny);
    padding: var(--ds-spacing-medium);
    color: var(--ds-colour-panel-dark);
    font-size: var(--ds-font-size-small);
    line-height: var(--ds-line-height-generous);

    &::placeholder {
        font-family: var(--ds-font-family-ds-averta);
        font-weight: var(--ds-font-weight-regular);
        font-size: var(--ds-font-size-small);
        line-height: var(--ds-line-height-generous);
    }

    &.textArea {
        height: var(--text-area-height);
        resize: none;
    }

    &.isInvalid {
        border: var(--ds-border-width-default) solid var(--input-error-colour);
    }

    &:focus {
        border: var(--ds-border-width-thin) solid var(--ds-colour-panel-dark);
        outline: none !important;
    }
}

.buttonOutline {
    & > button:focus {
        outline-color: var(--submit-success-colour);
    }
}

.errorMessage {
    font-size: var(--ds-font-size-tiny);
    line-height: var(--ds-line-height-adequate);
    position: absolute;
    color: var(--input-error-colour);
    padding: var(--ds-spacing-micro) var(--ds-spacing-tiny);
}

.errorIcon {
    width: var(--error-icon-size);
    height: auto;
    position: absolute;
    top: var(--ds-spacing-huge);
    right: var(--ds-spacing-medium);
    color: var(--input-error-colour);
}

.submitMessage {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: var(--ds-spacing-big);
    line-height: var(--ds-line-height-generous);

    &.error {
        color: var(--input-error-colour);
    }
}

.submitIcon {
    width: var(--submit-icon-size);
    height: auto;
    margin-right: var(--ds-spacing-micro);

    &.success {
        color: var(--submit-success-colour);
    }

    &.error {
        color: var(--input-error-colour);
        padding: var(--ds-spacing-micro);
    }
}

.userEmail {
    font-weight: bolder;
}
