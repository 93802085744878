.twoPanelSection {
    --maxContainerSize: 1200px;
    --top-padding: 68px;
    --benefits-list-row-gap: 23px;
    --benefits-list-column-gap: 16px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--top-padding);
    color: var(--rnb-colour-white);
    overflow: hidden;
}

.heroPanel {
    --top-padding: 0;
    --bottom-padding: var(--rnb-spacing-large);
    --side-padding: var(--rnb-spacing-large);

    @media (--rnb-mq-small) {
        --bottom-padding: 98px;
    }

    @media (--rnb-mq-large) {
        --top-padding: var(--rnb-spacing-super);
        --bottom-padding: 90px;
        --side-padding: 40px;
    }

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: var(--maxContainerSize);
    padding: var(--top-padding) var(--side-padding) var(--bottom-padding);
}

.titleContainer {
    display: flex;
    align-items: center;
}

.svgContainer {
    width: 190px;
    margin-bottom: var(--rnb-spacing-tiny);

    @media (--rnb-mq-large) {
        width: 241px;
        margin-bottom: 0;
    }
}

.heroCta {
    margin-top: var(--rnb-spacing-large);
}

.cardsImage {
    --right-position: auto;
    --bottom-position: auto;
    --image-width: 35%;
    --minimum-image-width: 216px;
    --display: none;

    @media (--rnb-mq-small) {
        --right-position: -30px;
        --bottom-position: -10px;
        --display: block;
    }

    @media (--rnb-mq-large) {
        --right-position: 0;
        --bottom-position: -30px;
        --image-width: 364px;
    }

    position: absolute;
    z-index: var(--rnb-zindex-levitate);
    display: var(--display);
    right: var(--right-position);
    bottom: var(--bottom-position);
    width: var(--image-width);
    min-width: var(--minimum-image-width);
}

.benefits {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: var(--rnb-colour-chill);

    @supports (backdrop-filter: blur(40px)) {
        --backdrop-blur: 40px;

        background-color: rgba(var(--rnb-colour-chill-rgb), 0.5);
        backdrop-filter: blur(var(--backdrop-blur));
    }
}

.benefitsContent {
    --side-padding: var(--rnb-spacing-large);

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--maxContainerSize);

    @media (--rnb-mq-large) {
        --side-padding: 40px;

        flex-direction: row;
        align-items: center;
    }

    padding: 0 var(--side-padding);
}

.benefitsText {
    --padding-top: 20px;
    --padding-bottom: 0;

    @media (--rnb-mq-large) {
        --padding-top: 40px;
        --padding-bottom: 40px;
    }

    padding: var(--padding-top) 0 var(--padding-bottom);
}

.partners {
    --margin-top: 28px;
    --margin-bottom: 26px;

    display: flex;
    margin: var(--margin-top) 0 var(--margin-bottom);
}

.partnerLogo svg {
    --margin-right: var(--rnb-spacing-small);
    --logo-height: 19px;
    --logo-width: 90px;

    @media (--rnb-mq-small) {
        --logo-height: 23px;
    }

    @media (--rnb-mq-medium) {
        --margin-right: var(--rnb-spacing-big);
        --logo-height: 40px;
        --logo-width: 120px;
    }

    height: var(--logo-height);
    width: var(--logo-width);
    margin-right: var(--margin-right);
}

.phonesImage {
    --image-max-width: 511px;

    order: 1;
    margin: 0 auto;
    width: 100%;
    max-width: var(--image-max-width);

    @media (--rnb-mq-large) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        width: var(--image-max-width);
        margin-right: var(--rnb-spacing-large);
        order: -1;
    }
}

.list {
    display: grid;
    gap: var(--benefits-list-row-gap) var(--benefits-list-column-gap);
    grid-template-columns: auto 1fr;
    align-items: center;
    margin-bottom: var(--rnb-spacing-large);
}

.heroBackground {
    width: 100%;
    height: 100%;
}

.backgroundDesktop {
    position: absolute;
    top: 0;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: var(--rnb-zindex-dive);
    background-color: var(--rnb-colour-chill);
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.backgroundMobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: var(--rnb-zindex-dive);
    background-color: var(--rnb-colour-chill);
    display: inline;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.descriptionContainer {
    padding-right: 40%;

    @media (--rnb-mq-large) {
        padding-right: 20%;
    }
}

.descriptionMobile {
    display: block;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.descriptionDesktop {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.visuallyHidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}
