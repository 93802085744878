:root .component {
    --mega-loader-width: 16rem;
    --mega-loader-height: 16rem;
    --animation-text-fade-in: 2rem;
    --image-top-spacing: 2.5rem;
    --animation-text-fade-out: -2rem;
    --preloader-margin-top: 88px;
}

@keyframes fade {
    0% {
        opacity: 0;
        transform: translateX(var(--animation-text-fade-in)) scale(0.97);
    }

    20% {
        opacity: 1;
        transform: translateX(0) scale(1);
    }

    80% {
        opacity: 1;
        transform: translateX(0) scale(1);
    }

    100% {
        opacity: 0;
        transform: translateX(var(--animation-text-fade-out)) scale(0.97);
    }
}

@keyframes stop-fade {
    80% {
        opacity: 1;
        transform: translateX(0) scale(1);
    }

    100% {
        opacity: 1;
        transform: translateX(0) scale(1);
    }
}

.component {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img {
    width: 156px;
    height: 156px;
    max-width: 156px;
    max-height: 156px;
    margin-bottom: var(--rnb-spacing-large);

    & > img {
        object-fit: cover;
        border-radius: 50%;
    }
}

@keyframes image-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.imgReady {
    animation: image-fade-in var(--rnb-timing-fast);
}

.textContent {
    animation-name: fade;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
}

.stopFade {
    animation-name: none;
}

.description {
    margin: var(--rnb-spacing-small) auto 0;
    max-width: 250px;
}

.loader {
    height: 38px;
    width: 38px;
    margin-top: var(--rnb-spacing-huge);
    color: var(--rnb-colour-chill);
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    90% {
        opacity: 1;
        transform: scale(1.05);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.popIn {
    animation: pop-in var(--rnb-timing-fast);
}
