.link {
    --store-buttons-height: 2.6rem;

    @media (--ds-mq-small) {
        --store-buttons-height: 2.81rem;
    }

    @media (--ds-mq-large) {
        --store-buttons-height: 3.25rem;
    }

    color: var(--ds-colour-black);
    filter: drop-shadow(0 var(--ds-spacing-micro) var(--ds-spacing-tiny) var(--ds-colour-black-alpha-32));

    &:last-child {
        margin-left: var(--ds-spacing-small);
    }
}

.storeButton {
    height: var(--store-buttons-height);
    width: auto;
    transform: scale(1);
    transition: transform 0.15s;

    &:hover {
        transform-origin: center;
        transform: scale(1.05);
    }
}
