.benefit {
    --icon-size: 40px;
    --icon-side-length: 24px;
    --icon-content-gap-desktop: 20px;
    --background: rgba(26, 36, 48, 1);
    --background2: rgba(62, 56, 55, 1);
    --background3: rgba(196, 100, 102, 1);

    grid-row-gap: var(--rnb-spacing-small);
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.08);
    border-width: var(--rnb-border-width-thin);
    border-color: var(--rnb-colour-midnight-tint-8);
    border-radius: var(--rnb-border-radius-small);
    padding: var(--rnb-spacing-large);
    background-size: cover;
    column-gap: var(--rnb-spacing-small);
    display: flex;
    flex-direction: column;
    align-items: start;

    & + * {
        margin-top: var(--rnb-spacing-large);
    }

    @media (--rnb-mq-medium) {
        border-radius: var(--rnb-border-radius-medium);
    }

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-large) var(--rnb-spacing-medium);
    }

    .icon {
        display: inline-block;
        mask-image: var(--benefitMaskImage);
        mask-size: var(--icon-size);
        width: var(--icon-size);
        height: var(--icon-size);
        align-self: start;

        &.isExclusive {
            mask-size: var(--icon-size);
            width: var(--icon-size);
            height: var(--icon-size);
            position: inherit;
            top: 5px;
            left: 5px;
        }
    }

    &.SPECIAL {
        background: linear-gradient(
            97.98deg,
            var(--rnb-colour-dandelion-shade-48) 13.99%,
            var(--rnb-colour-sea-shade-32) 91.16%
        );

        .title {
            color: var(--rnb-colour-white);
        }

        .icon {
            color: var(--rnb-colour-white);
        }

        .description {
            color: var(--rnb-colour-white);
        }
    }

    &.NYE {
        background: linear-gradient(
            90.81deg,
            var(--background) 24.82%,
            var(--background2) 58.27%,
            var(--background3) 106.11%
        );

        .title {
            color: var(--rnb-colour-white);
        }

        .icon {
            color: var(--rnb-colour-white);
        }

        .description {
            color: var(--rnb-colour-white);
        }
    }

    &.FAST_TRACK {
        background: var(--rnb-colour-sky-shade-48);

        .title {
            color: var(--rnb-colour-white);
        }

        .icon {
            color: var(--rnb-colour-white);
        }

        .description {
            color: var(--rnb-colour-white);
        }
    }
}
