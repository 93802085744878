.title.isThemeLight,
.description.isThemeLight {
    color: var(--rnb-colour-midnight);
}

.title.isThemeDark,
.description.isThemeDark {
    color: var(--rnb-colour-white);
}

.component {
    display: flex;
    flex-direction: column;
}

.isAlignStart {
    align-items: flex-start;
    text-align: left;
}

.isAlignCenter {
    align-items: center;
    text-align: center;
}

.isAlignEnd {
    align-items: flex-end;
    text-align: right;
}
