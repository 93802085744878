/* stylelint-disable color-no-hex */
.grid {
    --image-alignment: center;
    --grid-max-width: 80rem;
    --grid-gutter: var(--rnb-spacing-medium);
    --grid-template-columns: auto;
    --grid-template: 'image' 'ratings' 'productTags' 'tripleLockGuarantee' 'productTitle' 'tripleFeatureGrid'
        'applyButton' 'disclaimer' 'productDetails' 'moreDetails' 'rewards' 'partnerInfo';
    --guaranteeTextColor: #1c8580;
    --grayIconColor: #979fa8;
    --sticky-safe-area-bottom-offset: calc(
        var(--navigationBarHeight) + env(safe-area-inset-bottom) + var(--rnb-spacing-medium)
    );

    @media (--rnb-mq-small) {
        --grid-gutter: var(--rnb-spacing-large);
    }

    @media (--rnb-mq-medium) {
        --image-width: 201px;
        --image-height: 127px;
        --image-alignment: center;
        --grid-gutter: var(--rnb-spacing-big);
        --grid-template-columns: 1fr 30%;
        --grid-template: 'content sidebar';
    }

    @media (--rnb-mq-large) {
        --grid-gutter: var(--rnb-spacing-super);
        --image-width: 248px;
        --image-height: 160px;
    }

    @media (--rnb-mq-huge) {
        --grid-gutter: 0;
    }

    &:not(.isSkewed) {
        /* line up with 'feature-grid' width (taking into account grid-gap) */
        --product-image-width: var(--image-width, calc(60% - var(--rnb-spacing-tiny)));
    }

    &.isSkewed {
        --image-alignment: center; /* credit-cards is always centered */
        --product-image-width: var(--image-width, 224px);
        --product-image-height: var(--image-width, 142px);
    }

    display: grid;
    grid-template: var(--grid-template);
    grid-template-columns: var(--grid-template-columns);
    grid-template-rows: auto;
    grid-column-gap: var(--rnb-spacing-huge);
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
    max-width: var(--grid-max-width);
    margin: 0 auto;
    padding-top: var(--rnb-spacing-big);

    @media (--rnb-mq-lt-medium) {
        &.isFloating {
            margin-bottom: var(--sticky-safe-area-bottom-offset);
        }
    }

    @media (--rnb-mq-medium) {
        padding-top: var(--rnb-spacing-huge);

        &.isFloatingVisible {
            margin-bottom: calc(var(--navigationBarHeight) + env(safe-area-inset-bottom) + var(--rnb-spacing-huge));
        }
    }
}

.content {
    grid-area: content;
}

.sidebar {
    grid-area: sidebar;
}

.content,
.sidebar {
    display: contents;
    @media (--rnb-mq-medium) {
        display: flex;
        flex-direction: column;
    }
}

.gridItemBackButton {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: var(--rnb-colour-white);
    box-shadow: var(--rnb-shadow-mountain), var(--rnb-shadow-mountain-top);

    &.isHidden {
        visibility: collapse;
        height: 0;
    }
}

.gridItemRewards {
    margin-bottom: var(--rnb-spacing-large);
}

.gridItemImage {
    grid-area: image;
    justify-self: var(--image-alignment); /* h-align loans image to the right (sm) + center (md+) */
    align-self: var(--image-alignment);
    margin-bottom: var(--rnb-spacing-medium);
    width: var(--product-image-width);

    &.isSkewed {
        justify-self: center; /* h-align loans image in the center */
        margin-bottom: var(--rnb-spacing-large); /* increase gap for cards which are rotated */
    }
}

.gridItemRatings {
    grid-area: ratings;
    justify-self: var(--image-alignment); /* h-align loans ratings to the right (sm) + center (md+) */
    margin-bottom: var(--rnb-spacing-medium);
    display: flex;
    justify-content: center;

    /* set width to image width to ensure we can center align in middle of the image */
    width: var(--product-image-width);

    &.isSkewed {
        justify-self: center; /* h-align cards image in the center */
    }

    @media (--rnb-mq-medium) {
        justify-self: center;
    }

    &:empty {
        display: none;
    }
}

.gridItemTripleLockGuarantee {
    grid-area: tripleLockGuarantee;
    margin-top: 0;

    @media (--rnb-mq-medium) {
        margin-bottom: var(--rnb-spacing-tiny);
    }
}

.gridItemProductTags {
    grid-area: productTags;
    position: relative;
    margin-top: 0;

    @media (--rnb-mq-medium) {
        ~ .gridItemTripleLockGuarantee {
            /* if both tags and lock are both showing we dont want margin above lock */
            margin-top: 0;
        }
    }
}

.gridItemProductTitle {
    grid-area: productTitle;
    margin-bottom: var(--rnb-spacing-medium);
    position: relative;
    z-index: 2;

    @media (--rnb-mq-medium) {
        margin-bottom: var(--rnb-spacing-small); /* align with the bottom of the product image */
    }
}

.gridItemTripleFeatureGrid {
    grid-area: tripleFeatureGrid;
    margin-bottom: var(--rnb-spacing-large);

    > * ~ * {
        margin-top: var(--rnb-spacing-medium);

        &:empty {
            display: none;
        }
    }
}

.tripleFeatureGridDisclaimer {
    display: none;

    @media (--rnb-mq-lt-medium) {
        &.isFloating {
            display: block;
            margin-top: var(--rnb-spacing-large);
        }
    }
}

.gridItemApplyButton {
    grid-area: applyButton;
    margin-bottom: var(--rnb-spacing-large);

    @media (--rnb-mq-lt-medium) {
        &.isFloating {
            bottom: 0;
            position: fixed;
            width: calc(100% - var(--rnb-spacing-huge));
            margin-bottom: var(--sticky-safe-area-bottom-offset);
        }
    }
}

.gridItemDisclaimer {
    grid-area: disclaimer;
    margin-bottom: var(--rnb-spacing-big);

    @media (--rnb-mq-lt-medium) {
        &.isFloating {
            display: none;
        }
    }
}

.gridItemProductDetails {
    grid-area: productDetails;
    margin-bottom: var(--rnb-spacing-big);
}

.gridItemPartnerInfo {
    grid-area: partnerInfo;
    margin-bottom: var(--rnb-spacing-big);
}

.gridItemMoreDetails {
    grid-area: moreDetails;
    margin-bottom: var(--rnb-spacing-big);
    color: var(--rnb-colour-midnight-tint-80);
}

/* UI */
.detailsContainer {
    flex: 1; /* set full height */
    position: relative;
    overflow: hidden;
    background-color: var(--bgColor, var(--rnb-colour-background-light));

    @media (--rnb-mq-medium) {
        --bgColor: var(--bgColor, var(--rnb-colour-white));
    }
}

.backButton {
    min-height: 60px;
    display: flex; /* needed to center align vertically */
    position: relative;
    color: var(--rnb-colour-midnight);
    max-width: var(--max-content-width);
    margin: 0 auto;
    padding-left: var(--rnb-spacing-large);
    padding-right: var(--rnb-spacing-large);
    /* ensure the color can be seen on the light bg once the blurred image is no longer behind it */

    @media screen and (min-width: 600px) {
        padding: 0;

        &.isSkewed {
            color: var(--rnb-colour-midnight);
        }
    }

    .cta {
        align-items: center;
        display: flex;
        color: currentColor;
        text-decoration: none;
        cursor: pointer;
    }

    .icon {
        margin-right: var(--rnb-spacing-small);
        transform: rotate(90deg);
    }
}

.productImageContainer {
    --cardRotation: 0deg; /* rotation needs 'deg', even '0' */
    --cardRadius: 0;
    --bgCardRotation: 21deg;

    display: flex;
    justify-content: center;

    /* credit-cards are 'CENTER_SKEWED' */
    &.isSkewed {
        --cardRotation: -10deg;
        --cardRadius: var(--rnb-border-radius-medium);
    }

    /* loans are 'RIGHT_OFFSET' */
    &:not(.isSkewed) {
        max-width: 250px; /* prevent image getting too big on mobile sizes */
        margin: 0 auto; /* center align on mobile sizes as max-width kciks in */
    }

    .productImage {
        position: relative;
        transform: rotate(var(--cardRotation));
        box-shadow: var(--rnb-shadow-hill), 0 0 8px 0 rgba(0, 0, 0, 0.08);
        max-width: 200px;
        max-height: 200px;
        background-color: transparent;
        border-radius: var(--rnb-spacing-small);

        @media (--rnb-mq-large) {
            max-width: 250px;
            max-height: 250px;
        }
    }
}

.productImageBlurredContainer {
    --height: 135px;
    --cardRotation: 0deg; /* rotation needs 'deg', even '0' */
    --top: 0;
    --left: 0;
    --right: unset;

    @media (--rnb-mq-medium) {
        --height: 119px;
    }

    &.isSkewed {
        --cardRotation: 19deg;
        --left: unset;
        --right: -2.1rem;
        --top: 0.5rem;
        --height: 210px;

        @media (--rnb-mq-medium) {
            --top: 0;
        }

        @media (--rnb-mq-large) {
            --top: 1em;
            --right: -2.5rem;
            --height: 225px;
        }

        transform: rotate(var(--cardRotation));
        transform-origin: right;
        min-width: 700px;
    }

    overflow: hidden;
    position: absolute;
    top: var(--top);
    left: var(--left);
    right: var(--right);
    height: var(--height);
    width: 100vw;

    .productImage {
        transform: translateY(-50%) scale(1.2) rotate(calc(-1 * var(--cardRotation)));
        filter: blur(20px);
        width: 100%;
        top: var(--height);
        position: relative;
    }
}

.productRating {
    display: inline-block;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.productTitle {
    color: var(--rnb-colour-midnight);
    font-size: var(--rnb-font-size-large);
}

.productSubTitles {
    margin-top: var(--rnb-spacing-small);
    color: var(--rnb-colour-midnight-tint-64);

    &:empty {
        display: none;
    }
}

.keyDetails,
.tripleFeatureGrid {
    display: grid;
    grid-template-columns: 40% auto;
    grid-template-rows: auto auto;
    grid-gap: var(--rnb-spacing-tiny);

    @media (--rnb-mq-medium) {
        grid-gap: var(--rnb-spacing-small);
    }

    @media (--rnb-mq-large) {
        grid-template-columns: 33% 33% auto;
        grid-template-rows: auto;
        grid-gap: var(--rnb-spacing-medium);
    }

    .tripleFeatureGridCell {
        border-radius: var(--rnb-border-radius-medium);
        box-shadow: var(--rnb-shadow-earth), 0 0 4px 0 rgba(0, 0, 0, 0.08);
        background-color: var(--rnb-colour-white);
        display: inline-block;
        color: var(--fontColor, var(--rnb-colour-midnight));
        transition: box-shadow var(--rnb-timing-fast) ease-in-out;

        .cellValue {
            display: flex;
        }

        .cellLabel {
            color: var(--fontColor, var(--rnb-colour-midnight-tint-64));
        }

        /* right align last child, if it's an odd number */
        &:last-child:nth-child(odd) {
            grid-column-start: 2;

            @media (--rnb-mq-large) {
                grid-column-start: unset;
            }
        }

        &.hasRichDescription {
            cursor: pointer;
            text-align: left;

            svg {
                flex-shrink: 0;
            }

            &:hover,
            &:focus {
                box-shadow: var(--rnb-shadow-mountain);
            }
        }

        svg {
            color: var(--grayIconColor);
        }

        &.HIGHLIGHTED {
            color: var(--guaranteeTextColor);

            svg {
                color: var(--guaranteeTextColor);
            }
        }
    }
}

.keyDetails {
    --itemColor: var(--rnb-colour-white);

    .keyDetailsItem {
        display: inline-block;
        border-radius: var(--rnb-border-radius-medium);
        background-color: var(--itemColor);
        color: var(--rnb-colour-midnight);

        .keyDetailsItemValue {
            display: flex;
        }

        .keyDetailsItemLabel {
            color: var(--rnb-colour-midnight-tint-64);
        }

        /* right align last child, if it's an odd number */
        &:last-child:nth-child(odd) {
            grid-column-start: 2;

            @media (--rnb-mq-large) {
                grid-column-start: unset;
            }
        }
    }
}

.disclaimer {
    color: var(--rnb-colour-midnight);

    .disclaimerDescription {
        color: var(--rnb-colour-midnight-tint-64);
    }
}

.tags {
    display: flex;
    justify-content: flex-start;
    gap: var(--rnb-spacing-small);
    flex-wrap: wrap;
}

/* Utils */
.hideDesktop {
    @media (--rnb-mq-medium) {
        display: none;
    }
}

.hideMobile {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }
}

.floatingCtaWrapper {
    visibility: hidden;
    left: 0;
    opacity: 0;
    width: 100%;
    display: flex;
    position: fixed;
    justify-content: center;
    padding: var(--rnb-spacing-large);
    box-shadow: var(--rnb-shadow-earth-top);
    background-color: var(--rnb-colour-white);
    transition: opacity var(--rnb-timing-fast) ease-in-out;

    .cta {
        width: fit-content;
        min-width: 20.5rem;
    }

    @media (--rnb-mq-medium) {
        bottom: calc(var(--navigationBarHeight) + env(safe-area-inset-bottom));
    }

    @media (--rnb-mq-large) {
        bottom: 0;
    }

    &.isVisible {
        @media (--rnb-mq-medium) {
            opacity: 1;
            visibility: visible;
        }
    }
}
