.productBanner {
    background: var(--rnb-colour-reef-shade-48);
    border-radius: var(--rnb-border-radius-medium) var(--rnb-border-radius-medium) 0px 0px;
    display: flex;
    flex-direction: column;
    color: var(--rnb-colour-white);
    position: relative;
    overflow: hidden;

    strong {
        font-weight: var(--rnb-font-weight-black);
    }

    button {
        font-size: var(--rnb-font-size-tiny);
        font-weight: var(--rnb-font-weight-bold);
        padding-left: var(--rnb-spacing-micro);
        background: none;
        cursor: pointer;
    }

    .titleWrapper {
        display: flex;
        flex-direction: row;
        align-items: end;
        font-size: 18px;
        line-height: 22px;
    }

    .blurredBackground {
        display: block;
        margin-left: var(--rnb-spacing-large);
        margin-right: var(--rnb-spacing-large);
        margin-top: var(--rnb-spacing-tiny);
        margin-bottom: var(--rnb-spacing-tiny);
        background: var(--rnb-colour-black-alpha-16);
        backdrop-filter: blur(24px);
        border-radius: var(--rnb-border-radius-small);
        padding: var(--rnb-spacing-tiny);

        @media (--rnb-mq-medium) {
            width: 50%;
        }
    }

    .bgImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        picture,
        source,
        img {
            position: absolute;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
        }
    }

    &.TRIPLE_LOCK,
    &.FAST_TRACK {
        .blurredBackground {
            background: var(--rnb-colour-black-alpha-32);
        }
    }

    &.midnight {
        background: var(--rnb-colour-midnight);
    }
}

.content {
    background: var(--rnb-colour-reef-shade-48);
    overflow-x: hidden;
    color: var(--rnb-colour-white);
    width: 100vw;
    max-width: 652px;
    border-radius: var(--rnb-border-radius-large);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: var(--rnb-spacing-jumbo) 0;
    row-gap: var(--rnb-spacing-huge);

    @media (--rnb-mq-medium) {
        padding: var(--rnb-spacing-super) 0;
    }

    picture,
    img {
        min-width: 100%;
        height: 100%;
        position: fixed;
    }

    &.midnight {
        background: var(--rnb-colour-midnight);
    }
}

.contentInner {
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: var(--rnb-spacing-large);
    width: 90%;
    background: var(--rnb-colour-black-alpha-32);
    backdrop-filter: blur(24px);
    border-radius: var(--rnb-border-radius-small);

    @media (--rnb-mq-medium) {
        width: 76%;
        padding: var(--rnb-spacing-big) var(--rnb-spacing-super);
    }
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: end;
    font-size: 18px;
    line-height: 22px;
}

.featureListDescription {
    margin-top: var(--rnb-spacing-big);
    margin-bottom: var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        padding: 0 var(--rnb-spacing-big);
    }
}
.listWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    svg {
        color: var(--rnb-colour-white);
        border-radius: var(--rnb-border-radius-circle);
    }
}
.listItem {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.subtitle {
    text-align: left;
    font-size: 14px;
}

.richDescription {
    text-align: left;

    @media (--rnb-mq-large) {
        padding: 0 var(--rnb-spacing-big);
    }
}

.cta {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
