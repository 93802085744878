.cardDetails {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.header {
    margin-bottom: var(--rnb-spacing-small);
    margin-top: var(--rnb-spacing-tiny);
    display: flex;
}

.footer {
    display: flex;
    margin-top: auto;
    font-size: var(--rnb-font-size-tiny);
    box-sizing: border-box;
    display: inline-block;
    padding: 0 var(--rnb-spacing-medium) var(--rnb-spacing-medium) var(--rnb-spacing-medium);
}

.footerDivider {
    border-top: solid 1px var(--rnb-colour-black-alpha-16);
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    padding: var(--rnb-spacing-tiny) var(--rnb-spacing-medium);
}
