:root .component {
    --nubSize: 8px;
}

.component {
    border-radius: var(--rnb-border-radius-small);
    box-shadow: var(--rnb-shadow-earth);
    max-width: 320px;
    padding: var(--rnb-spacing-small);
    position: relative;
    text-align: center;
    z-index: var(--rnb-zindex-rise);

    &.isAlignmentRight {
        text-align: right;
    }

    &.isAlignmentLeft {
        text-align: left;
    }

    @media (--rnb-mq-medium) {
        padding: var(--rnb-spacing-medium);
    }

    &::after {
        border: solid transparent;
        content: '';
        height: 0;
        position: absolute;
        width: 0;
    }

    &.isPlacementBottom::after,
    &.isPlacementTop::after,
    &.isPlacementTopLeft::after,
    &.isPlacementTopRight::after,
    &.isPlacementBottomLeft::after,
    &.isPlacementBottomRight::after {
        border-width: 0 var(--nubSize);
    }

    &.isPlacementBottom::after,
    &.isPlacementTop::after {
        left: 50%;
        transform: translateX(-50%);
    }

    &.isPlacementBottom::after,
    &.isPlacementBottomLeft::after,
    &.isPlacementBottomRight::after {
        border-bottom: var(--nubSize) solid currentColor;
        bottom: 100%;
    }

    &.isPlacementTop::after,
    &.isPlacementTopLeft::after,
    &.isPlacementTopRight::after {
        border-top: var(--nubSize) solid currentColor;
        top: 100%;
    }

    &.isPlacementTopLeft::after,
    &.isPlacementBottomLeft::after {
        left: 8%;
    }

    &.isPlacementBottomRight::after,
    &.isPlacementTopRight::after {
        right: 8%;
    }

    &.isPlacementLeft::after,
    &.isPlacementRight::after {
        border-width: var(--nubSize) 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &.isPlacementLeft::after {
        border-left: var(--nubSize) solid currentColor;
        left: 100%;
    }

    &.isPlacementRight::after {
        border-right: var(--nubSize) solid currentColor;
        right: 100%;
    }

    &.isThemeLight {
        background-color: var(--rnb-colour-midnight);
        color: var(--rnb-colour-white);

        &::after {
            color: var(--rnb-colour-midnight);
        }
    }

    &.isThemeDark {
        background-color: var(--rnb-colour-white);
        color: var(--rnb-colour-midnight);

        &::after {
            color: var(--rnb-colour-white);
        }
    }
}

.closeIcon {
    position: absolute;
    right: var(--rnb-spacing-medium);
}
