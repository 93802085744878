:root .component {
    --ds-colour-inherit: inherit;
}

.component {
    text-decoration: underline;
    color: var(--ds-colour-inherit);

    /* THEMES */
    &.isThemePositive {
        color: var(--ds-colour-highlight-green);
    }

    &.isThemeWarning {
        color: var(--ds-colour-highlight-pink);
    }

    &.isThemeLight {
        color: var(--ds-colour-white);
    }
}

.isSimpleLink {
    text-decoration: none;
}
