.inlineLink {
    cursor: pointer;
    text-decoration: underline;
    color: var(--rnb-colour-chill);
    font-size: var(--rnb-font-size-mini);
    line-height: var(--rnb-line-height-text);
    font-weight: var(--rnb-font-weight-bold);

    &:hover,
    &:focus {
        color: var(--rnb-colour-chill-tint-64);
    }
}
