:root .section {
    --maxContainerSize: 848px;
    --iconWidth: 30px;
    --checkIconSize: 25px;
    --side-padding: 24px;

    color: var(--rnb-colour-white);
    position: relative;
}

.showPhablet {
    display: none;

    @media (--rnb-mq-phablet) {
        display: unset;
    }
}

.hiddenPhablet {
    @media (--rnb-mq-phablet) {
        display: none;
    }
}

.background {
    width: 100%;
    height: 100%;

    .imgBackground {
        position: absolute;
        width: 100%;
        overflow: hidden;
        z-index: -1;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        img {
            display: block;
            object-fit: cover;
        }
    }
}

.howItWorks {
    max-width: var(--maxContainerSize);
    position: relative;
    padding: var(--rnb-spacing-big) var(--rnb-spacing-large);
    width: 100%;
    margin: 0 auto;

    @media (--rnb-mq-large) {
        display: flex;
    }
}

.container {
    display: flex;
    align-items: center;
    flex-flow: row;
    width: 100%;
    padding: var(--side-padding) 0;

    @media (--rnb-mq-large) {
        padding: 0;
    }
}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (--rnb-mq-large) {
        padding-right: var(--rnb-spacing-big);
    }
}

.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: var(--rnb-spacing-tiny);

    @media (--rnb-mq-large) {
        padding-top: 0;
    }
}

.tripleLockPhone {
    --max-image-width: 264px;

    max-width: var(--max-image-width);
    width: 100%;
}

.displayTitleContainer {
    @media (--rnb-mq-phablet) {
        justify-content: flex-start;
    }
}

.titleContainer {
    flex-direction: row;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--rnb-spacing-medium);
}

.blurbContainer {
    align-items: flex-start;
    width: 100%;
    padding-left: var(--rnb-spacing-medium);
}

.icon {
    svg {
        width: var(--iconWidth);
    }
}

.listItem {
    display: flex;
    flex-direction: row;
    white-space: wrap;
    margin-bottom: var(--rnb-spacing-tiny);
    justify-content: flex-start;

    > * {
        margin-right: var(--rnb-spacing-tiny);
    }
}

.itemNumerate {
    padding-right: var(--rnb-spacing-tiny);
}

.checkIcon {
    height: var(--checkIconSize);
    width: var(--checkIconSize);
}

.displayPhablet {
    @media (--rnb-mq-phablet) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.bottomContainer {
    line-height: var(--ds-line-height-large);
    width: 100%;
    padding-top: var(--rnb-spacing-medium);
}
