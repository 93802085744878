:root .popover {
    --mobile-popover-width: 190px;
}

.icon {
    &.isThemeLight {
        color: var(--rnb-colour-chill);
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);
    }
}

.popover {
    width: var(--mobile-popover-width);
    position: absolute;
    visibility: hidden;
    z-index: var(--rnb-zindex-levitate);

    @media (--rnb-mq-medium) {
        /* IE11 will fall back to width 190px */
        width: max-content;
    }

    &.isShown {
        visibility: visible;
    }
}
