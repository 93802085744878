.body {
    padding: var(--rnb-spacing-super) var(--rnb-spacing-none);

    @media (--rnb-mq-medium) {
        padding: var(--rnb-spacing-super) var(--rnb-spacing-super);
    }
}

.snippets {
    @media (--rnb-mq-medium) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
