.cardDetails {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.header {
    margin-bottom: var(--rnb-spacing-medium);
    display: flex;
}

.footer {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    border-radius: inherit;
}
