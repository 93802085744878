:root {
    --drawer-width-m: 480px;
    --drawer-top-padding-s: 72px;
    --feature-list-with-description-item-image-height: 24px;
}

.partnerInfoDrawer {
    width: 100vw;
    height: 100vh;
    padding: var(--drawer-top-padding-s) var(--rnb-spacing-large) var(--rnb-spacing-large);
    color: var(--rnb-colour-midnight);
    background-color: var(--rnb-colour-white);

    @media (--rnb-mq-medium) {
        width: var(--drawer-width-m);
        padding: var(--rnb-spacing-super) var(--rnb-spacing-large) var(--rnb-spacing-large);
    }
}

.featureListWithDescriptionItemImage > img {
    max-height: var(--feature-list-with-description-item-image-height);
}
