:root {
    --detail-height: 48px;
    --two-colum-minus-margin: calc(var(--rnb-spacing-tiny) / 2);
    --three-colum-minus-margin: calc(var(--rnb-spacing-tiny) / 2 * 1.5);
}

.component {
    display: flex;
    flex-wrap: wrap;
    color: var(--rnb-colour-black-alpha-64);
}

.detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: var(--detail-height);
    background-color: var(--rnb-colour-black-alpha-4);
    border-radius: var(--rnb-border-radius-small);
    padding: 0 var(--rnb-spacing-medium);
    margin-bottom: var(--rnb-spacing-tiny);

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (--rnb-mq-medium) {
        width: calc(50% - var(--two-colum-minus-margin));

        &:nth-child(odd) {
            margin-right: var(--rnb-spacing-tiny);
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
            margin-bottom: 0;
        }
    }

    @media (--rnb-mq-large) {
        width: calc(33.333% - var(--three-colum-minus-margin));

        &:nth-of-type(3n + 1),
        &:nth-of-type(3n + 2) {
            margin-right: var(--rnb-spacing-tiny);
        }

        &:nth-of-type(3n + 3) {
            margin-right: 0;
        }

        &:nth-last-child(3) {
            margin-bottom: 0;
        }
    }
}

.detailContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &.stackedContent {
        flex-direction: column;
        justify-content: center;
        width: initial;
        align-items: flex-start;
    }
}

.detailTitle {
    margin-right: var(--rnb-spacing-medium);
}
