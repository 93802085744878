.heroRatingWithDistribution {
    --averageHeroRatingColumnWidth: 90px;
    --distributed-rating-font-size: 0.5rem;
    --percentageBarHeight: 4px;
    --maxBarWidth: 195px;

    @media (--rnb-mq-medium) {
        --maxBarWidth: 302px;
    }

    @media (--rnb-mq-large) {
        --maxBarWidth: 231px;
    }

    display: grid;
    grid-template-columns: var(--averageHeroRatingColumnWidth) 1fr;
    grid-column-gap: var(--rnb-spacing-big);
}

.distribution {
    display: grid;
    grid-template-rows: repeat(5, var(--percentageBarHeight));
    grid-row-gap: var(--rnb-spacing-tiny);
    align-self: center;
}

.distributionRow {
    display: flex;
    align-items: center;

    .distributedRating {
        margin-right: var(--rnb-spacing-micro);
        font-size: var(--distributed-rating-font-size);
        color: var(--rnb-colour-midnight-tint-48);
    }

    .percentageBar {
        width: var(--maxBarWidth);
        background-color: var(--rnb-colour-midnight-tint-8);
    }

    .percentageBarFill {
        background-color: var(--rnb-colour-sand);
    }
}

.percentageBar,
.percentageBarFill {
    height: var(--percentageBarHeight);
    border-radius: var(--rnb-border-radius-medium);
}
