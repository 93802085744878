.guarantee {
    display: flex;
    align-items: center;
    font-size: var(--rnb-font-size-tiny);

    .guaranteeLabel {
        margin-right: var(--rnb-spacing-micro);
    }
}

.guaranteeMaskIcon {
    mask-image: var(--guaranteeMaskImage);
    background: currentColor;
    display: inline-block;
    mask-size: 20px;
    width: 20px;
    height: 20px;
}
