.categoryRatings {
    --categoryIconSideLength: 40px;
    --percentageBarHeight: 4px;
    --width: 100%;

    @media (--rnb-mq-large) {
        --width: 363px;
    }

    width: var(--width);
}

.categoriesList {
    display: grid;
    grid-template-rows: repeat(3, var(--categoryIconSideLength));
    grid-row-gap: var(--rnb-spacing-large);
}

.category {
    display: grid;
    grid-template-columns: var(--categoryIconSideLength) auto;
    grid-column-gap: var(--rnb-spacing-medium);
}

.categoryDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.categoryIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--categoryIconSideLength);
    height: var(--categoryIconSideLength);
    border-radius: var(--rnb-border-radius-circle);
    color: var(--rnb-colour-white);
}

.headline {
    display: flex;
    justify-content: space-between;
}

.categoryDescription {
    color: var(--rnb-colour-midnight-tint-64);
}

.percentagePositive {
    display: flex;
    align-items: center;
    color: var(--rnb-colour-reef-shade-64);
}

.percentageBar,
.percentageBarFill {
    height: var(--percentageBarHeight);
    border-radius: var(--rnb-border-radius-medium);
}

.percentageBar {
    background-color: var(--rnb-colour-midnight-tint-8);
}

.percentageBarFill {
    background-color: var(--rnb-colour-reef-shade-64);
}
