.component {
    position: relative;
    width: 100%;
}

.card {
    height: 100%;
    padding-bottom: var(--rnb-spacing-medium);
}

.trustpilot {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: var(--rnb-spacing-large);
}
