.faqsSection {
    --maxContainerSize: 848px;
    --padding-top: var(--rnb-spacing-large);
    --padding-bottom: var(--rnb-spacing-big);

    padding: var(--padding-top) var(--rnb-spacing-large) var(--padding-bottom);
    color: var(--rnb-colour-midnight);
    width: 100%;
    max-width: var(--maxContainerSize);
    margin: 0 auto;
}

.faq {
    border-radius: var(--rnb-border-radius-medium);
    box-shadow: 0 0.5px 1.75px rgba(0, 0, 0, 0.039), 0 1.85px 6.25px rgba(0, 0, 0, 0.19);
    margin-bottom: var(--rnb-spacing-medium);
}

.lastFaq {
    margin-bottom: var(--rnb-spacing-large);
}

.link {
    color: var(--rnb-colour-midnight);
}

.example {
    display: grid;
    grid-template-columns: 1fr auto;
    row-gap: var(--rnb-spacing-tiny);
}

.secondaryText {
    color: var(--rnb-colour-midnight-tint-64);
}

.lastRow {
    color: var(--rnb-colour-midnight);
    font-weight: var(--rnb-font-weight-medium);
}

.type {
    display: flex;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--rnb-colour-chill);
    padding: 0 var(--rnb-spacing-medium) 0 var(--rnb-spacing-micro);
}

.icon svg {
    --icon-size: 32px;

    height: var(--icon-size);
}

.lastIcon {
    transform: scaleY(-1);
}

.cta {
    display: flex;
    justify-content: center;
}

.disclaimer {
    text-align: center;
    margin-top: var(--rnb-spacing-medium);
}
