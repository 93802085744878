.backdrop {
    z-index: var(--rnb-zindex-top);
    position: fixed;
    inset: 0;
    background-color: var(--rnb-colour-black-alpha-64);
    visibility: hidden;
    opacity: 0;
    transition: visibility var(--rnb-timing-fast) linear, opacity var(--rnb-timing-fast) linear;

    &.isOpenTransition {
        opacity: 1;
        visibility: visible;
    }
}

.content {
    z-index: var(--rnb-zindex-top);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    justify-content: center;
    overflow: auto;
    transition: visibility var(--rnb-timing-fast) linear, opacity var(--rnb-timing-fast) linear;
    visibility: hidden;
    opacity: 0;

    &.isOpenTransition {
        opacity: 1;
        visibility: visible;
    }
}
