.sectionHeader {
    --font-size-small: 1.87rem;
    --font-size-large: 3.25rem;
    --line-height: 1.16;

    /* Custom typography only for marketing site */
    font-size: var(--font-size-small);
    font-weight: var(--ds-font-weight-semi-bold);
    font-family: var(--ds-font-family-ds-averta);
    line-height: var(--line-height);

    @media (--ds-mq-large) {
        font-size: var(--font-size-large);
    }
}
