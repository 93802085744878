.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.corner-image {
    max-width: 50px;
    max-height: 50px;
}

.title-container {
    margin-top: auto;
    padding-top: var(--rnb-spacing-micro);
}

.title-container--display-state-dpo_viewed,
.title-container--display-state-dpo_applied {
    color: var(--rnb-colour-midnight);
}

.label-container {
    display: flex;
    padding-top: var(--rnb-spacing-tiny);
    padding-bottom: var(--rnb-spacing-small);
}

.subtitle-container {
    display: flex;
    margin-top: var(--rnb-spacing-micro);
}

.overline {
    border: 1px solid var(--rnb-colour-midnight-tint-8);
    margin: var(--rnb-spacing-small) auto;
}

.overline--display-state-dpo_viewed,
.overline--display-state-dpo_applied {
    border: 1px solid var(--rnb-colour-midnight);
}
