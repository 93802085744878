.greatAboutDrivescoreSection {
    --max-width-features: 80rem;
    --column-gap-large: 5rem;
    --column-gap-huge: 14.5rem;
    --icon-background-size-small: 3.75rem;
    --icon-background-size-large: 5.5rem;
    --icon-size-small: 2rem;
    --icon-size-large: 2.875rem;
    --max-text-width: 26rem;
    --margin-top: 10rem;

    @media (--ds-mq-large) {
        --margin-top: 15rem;
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: var(--margin-top);
}

.featuresLayout {
    display: grid;
    margin-top: calc(var(--ds-spacing-super) + var(--ds-spacing-small));
    row-gap: calc(var(--ds-spacing-super) + var(--ds-spacing-micro));
    text-align: left;
    max-width: var(--max-width-features);

    @media (--ds-mq-large) {
        margin-top: calc(var(--ds-spacing-huge) + var(--ds-spacing-super));
        grid-template-columns: 1fr 1fr;
        column-gap: var(--column-gap-large);
        row-gap: calc(var(--ds-spacing-super) + var(--ds-spacing-large));
    }

    @media (--ds-mq-huge) {
        column-gap: var(--column-gap-huge);
    }
}

.featureWrapper {
    display: flex;
}

.iconBackground {
    background-color: var(--ds-colour-panel);
    border-radius: var(--ds-border-radius-circle);
    min-width: var(--icon-background-size-small);
    max-height: var(--icon-background-size-small);
    margin-right: calc(var(--ds-spacing-big) + var(--ds-spacing-micro));
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--ds-mq-large) {
        min-width: var(--icon-background-size-large);
        max-height: var(--icon-background-size-large);
    }
}

.icon {
    color: var(--ds-colour-highlight-green);
    width: var(--icon-size-small);
    height: var(--icon-size-small);

    @media (--ds-mq-large) {
        width: var(--icon-size-large);
        height: var(--icon-size-large);
    }
}

.description {
    color: var(--ds-colour-secondary);
    max-width: var(--max-text-width);
}
