.dobBox {
    width: 100%;

    display: flex;
    justify-content: space-between;

    > * {
        flex: 1;
        margin-right: var(--rnb-spacing-medium);
    }

    > :nth-child(2) {
        flex: 2;
    }

    > :last-child {
        margin-right: 0;
    }

    @media (--rnb-mq-phablet) {
        > :nth-child(2) {
            flex: 1;
        }
    }
}

.moveInBox {
    width: 100%;

    display: flex;
    justify-content: space-between;

    > :first-child {
        flex: 3;
        margin-right: var(--rnb-spacing-medium);

        @media (--rnb-mq-phablet) {
            margin-right: var(--rnb-spacing-huge);
        }
    }

    > :last-child {
        flex: 2;
    }
}

.addNewAddressBox {
    margin-top: var(--rnb-spacing-large);
    margin-bottom: var(--rnb-spacing-medium);
}

.addNewAddressContentBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: var(--rnb-spacing-medium);

    button {
        width: 100%;
        max-width: 400px;
    }
}

.addNewAddressTopContent {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--rnb-spacing-medium);

    > :first-child {
        min-width: 24px;
    }
}

.addNewAddressInfoText {
    text-align: left;
    margin-left: var(--rnb-spacing-medium);
    line-height: 1.5;
}

.submitBox {
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: space-between;
    margin-top: var(--rnb-spacing-medium);

    @media (--rnb-mq-large) {
        height: auto;
        flex-direction: row;
        justify-content: flex-start;

        > :first-child {
            margin-right: var(--rnb-spacing-medium);

            div {
                width: 300px;
            }
        }
    }
}

.marketingCaption {
    p {
        display: inline-block;
        margin: 0 0.2em;
    }
}

.termsAndConditionsCaption {
    font-size: var(--rnb-font-size-mini);
    line-height: var(--rnb-line-height-text);
    color: var(--rnb-colour-midnight-tint-80);
    white-space: pre-line;

    * {
        color: var(--rnb-colour-midnight-tint-80);
        font-size: var(--rnb-font-size-mini);
        line-height: var(--rnb-line-height-text);
        display: inline-block;
        font-weight: var(--rnb-font-weight-bold);
    }
}

.ctaRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButton {
    display: flex;
    align-items: flex-end;

    text-decoration: none;
    color: var(--rnb-colour-action-light);
    font-weight: 700;
    cursor: pointer;
}

.actionText {
    line-height: 100%;
}

.removeInEditBox {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 100%;
        max-width: 500px;
    }
}
