.detailsSubheader {
    width: 100%;
    border-bottom: var(--rnb-border-width-thin) solid var(--rnb-colour-black-alpha-8);
    margin-bottom: var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        margin-bottom: var(--rnb-spacing-large);
    }

    .title {
        margin-bottom: var(--rnb-spacing-small);

        @media (--rnb-mq-medium) {
            margin-bottom: var(--rnb-spacing-medium);
        }
    }
}
