/* stylelint-disable color-no-hex */
:root {
    --explainerCopyTextColor: #263648;
}

.explainerWrapper {
    display: flex;
    font-size: var(--rnb-font-size-tiny);
    line-height: var(--rnb-font-size-small);
    gap: 5px;
    color: var(--explainerCopyTextColor);

    svg {
        color: var(--grayIconColor);
    }
}

.informationMaskIcon {
    mask-image: var(--informationMaskImage);
    background: currentColor;
    display: inline-block;
    mask-size: 16px;
    width: 16px;
    height: 16px;
}
