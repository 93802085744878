/* stylelint-disable color-no-hex */
.panel {
    --cardHeight: 131px;
    --minimumCardWidthVisible: 44px;
    --cardRotation: rotate(-8deg);
    --loan-image-width: 96px;
    --guaranteeTextColor: #1c8580;
    --grayIconColor: #979fa8;

    @media (--rnb-mq-medium) {
        --cardHeight: 157px;
        --loan-image-width: 108px;
    }

    @media (--rnb-mq-large) {
        --cardHeight: 185px;
        --loan-image-width: 146px;
    }

    background-size: cover;
    border-style: solid;
    border-top-width: var(--rnb-border-width-thin);
    border-bottom-width: var(--rnb-border-width-thin);
    border-color: var(--rnb-colour-white);
    background-position: center;

    /* ensure the overhanging credit card doesn't cause scrollbars */
    overflow: hidden;

    @media (--rnb-mq-medium) {
        border-radius: var(--rnb-border-radius-medium);
        border-left-width: var(--rnb-border-width-thin);
        border-right-width: var(--rnb-border-width-thin);
    }
}

.withBanner {
    border-radius: 0;
    border-top: none;
}

.panelGrid {
    display: grid;
    grid-template-columns: 0 auto 0;
    grid-template-rows: repeat(7, min-content);
    grid-column-gap: var(--rnb-spacing-medium);

    /*
        . tags .
        . titleGroup .
        . featureGrid featureGrid
        . guarantee .
        . primaryCta .
        . secondaryCta .
        . legal .
    */

    .tagsPosition {
        grid-column-start: 2;
        grid-row-start: 1;
    }

    .titleGroupPosition {
        grid-column-start: 2;
        grid-row-start: 2;
    }

    .featureGridPosition {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 3;

        &.hasRightPadding {
            grid-column-start: 2;
            grid-column-end: 3;
        }
    }

    .guaranteePosition {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 4;
    }

    .explainerCopyPosition {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 4;
    }

    .primaryCtaPosition {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 5;
    }

    .secondaryCtaPosition {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 6;
    }

    .legalPosition {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 7;
    }

    @media (--rnb-mq-medium) {
        grid-template-columns: 0 minmax(min-content, max-content) minmax(min-content, max-content) 1fr 0;

        /*
            . tags tags tags .
            . titleGroup titleGroup titleGroup .
            . featureGrid featureGrid featureGrid .
            . guarantee guarantee guarantee .
            . primaryCta secondaryCta . .
            . legal legal legal .
        */

        .tagsPosition,
        .titleGroupPosition,
        .featureGridPosition,
        .featureGridPosition.hasRightPadding,
        .guaranteePosition,
        .legalPosition {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        .primaryCtaPosition {
            grid-column-start: 2;
            grid-column-end: 3;
            align-self: center;
        }

        .secondaryCtaPosition {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 5;
            align-self: center;
        }

        .explainerCopyPosition {
            grid-column-end: 5;
        }
    }
}

/* title-group has it's own grid to ensure the product images always aligns with the title
 * nb: title can change position based on the response from the feature service
*/

.titleGroupGrid {
    display: grid;
    grid-template-columns: minmax(50%, max-content) auto;
    grid-template-rows: repeat(4, auto);
    grid-row-gap: var(--rnb-spacing-micro);
    grid-column-gap: var(--rnb-spacing-small);

    @media (--rnb-mq-small) {
        grid-template-columns: 1fr var(--loan-image-width);
    }

    .productImagePosition {
        grid-column-start: 2;
        grid-row-start: 2;
        grid-row-end: 4;
        align-self: flex-start;

        @media (--rnb-mq-medium) {
            grid-row-end: 5;
        }
    }

    .col1 {
        grid-column-start: 1;
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (--rnb-mq-small) {
            width: unset;
        }
    }

    .row0 {
        grid-row-start: 1;
    }

    .row1 {
        grid-row-start: 2;
        margin-bottom: 0;
        align-items: flex-start;

        @media (--rnb-mq-medium) {
            margin-bottom: var(--rnb-spacing-tiny);
        }
    }

    .row2 {
        grid-row-start: 3;
        margin-bottom: var(--rnb-spacing-micro);
    }

    .row3 {
        grid-row-start: 4;
        margin-bottom: var(--rnb-spacing-micro);
    }
}

/* END setup layout */

/* START ui */
.tags {
    display: flex;
    row-gap: var(--rnb-spacing-small);
    flex-wrap: wrap;
    justify-content: flex-end;

    > * ~ * {
        margin-left: var(--rnb-spacing-small);
    }

    @media (--rnb-mq-large) {
        justify-content: flex-start;

        > * {
            margin-left: var(--rnb-spacing-none);
            margin-right: var(--rnb-spacing-small);
        }
    }

    .tagMaskIcon {
        mask-image: var(--tagMaskImage);
        background: currentColor;
        display: inline-block;
        mask-size: 100%; /* TODO: remove once we have SVGs */
        width: 100%;
        height: 100%;
    }
}

.featureGrid {
    background-color: var(--rnb-colour-midnight-tint-4);
    border-radius: var(--rnb-border-radius-medium) 0 0 var(--rnb-border-radius-medium);
    width: 100%;

    /* force layout: child margin now affects this elements height */
    display: inline-block;

    @media (--rnb-mq-medium) {
        border-radius: var(--rnb-border-radius-medium);
    }

    &.hasShadow {
        box-shadow: var(--rnb-shadow-earth), 0 0 4px 0 rgba(0, 0, 0, 0.08);
        background-color: var(--rnb-colour-white);
    }

    &.hasRightPadding {
        border-radius: var(--rnb-border-radius-medium);
    }

    .extendedGrid {
        grid-template-columns: repeat(var(--col-count), auto);

        @media (--rnb-mq-huge) {
            grid-template-columns: repeat(4, auto);
        }
    }

    .details {
        display: grid;
        gap: var(--rnb-spacing-small);
        overflow: hidden;
    }

    .featureGridContent,
    .featureGridContentNoImg {
        display: grid;
        grid-template-columns: 80% auto;

        @media (--rnb-mq-phablet) {
            grid-template-columns: 90% auto;
        }

        .featureGridContentItem {
            flex: 1;
            overflow: hidden;

            svg {
                color: var(--grayIconColor);
            }

            &.HIGHLIGHTED {
                color: var(--guaranteeTextColor);

                svg {
                    color: var(--guaranteeTextColor);
                }
            }

            > div {
                > div {
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }

        svg {
            flex-shrink: 0;
        }
    }

    .featureGridContentNoImg {
        grid-template-columns: auto;
    }

    .featureGridValue {
        display: flex;

        > * ~ * {
            margin-left: var(--rnb-spacing-micro);
        }

        &:empty {
            display: none;
        }
    }

    .richDescriptionCta {
        cursor: pointer;
        text-decoration: underline;
        text-align: left;

        &:hover,
        &:focus {
            color: var(--rnb-colour-chill-tint-64);
        }
    }

    .productImage {
        position: relative;
        min-width: var(--minimumCardWidthVisible);

        img {
            height: var(--cardHeight);
            transform: var(--cardRotation);
            position: absolute;
            bottom: var(--rnb-spacing-medium);
            left: 0;
            box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.24);
            border-radius: var(--rnb-border-radius-medium);

            @media (--rnb-mq-medium) {
                bottom: calc(-2 * var(--rnb-spacing-medium));
            }

            @media (--rnb-mq-large) {
                bottom: calc(-2 * var(--rnb-spacing-large));
            }
        }
    }
}

.productImageTopRight {
    align-self: end;

    img {
        display: block;
        width: var(--loan-image-width);
        box-shadow: 0 12px 24px -12px var(--rnb-colour-black-alpha-32);
    }
}

.guarantee {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    .guaranteeLabel {
        margin-right: var(--rnb-spacing-micro);
    }
}

.secondaryCta {
    font-size: var(--rnb-font-size-mini);
    font-weight: var(--rnb-font-weight-bold);
    text-align: center;
}

.tripleLockGuaranteeVariant {
    display: flex;
    flex-direction: row;
}

.calloutText {
    display: flex;
    padding: var(--rnb-spacing-small) var(--rnb-spacing-medium);
    align-items: center;
    gap: var(--rnb-spacing-medium);
    align-self: stretch;
    background-color: var(--rnb-colour-white);
    border-radius:  var(--rnb-spacing-small)
}

strong { font-weight: bold; }

/* END ui */
