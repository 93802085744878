.component {
    position: relative;
    z-index: 1;
    grid-column-start: 1;
    grid-column-end: -1;
    white-space: pre-line;
}

.header {
    white-space: pre-line;
    font-weight: bold;
}

.flexBox {
    display: flex;
    justify-content: center;
}

.formBox {
    max-width: 635px;
    margin: 0 var(--rnb-spacing-large);
}

.form {
    padding-left: var(--rnb-spacing-large);
    padding-right: var(--rnb-spacing-large);
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--rnb-colour-white);
    z-index: var(--rnb-zindex-rise);
    display: flex;
    align-items: center;
    justify-content: center;
}
