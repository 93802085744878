.partnerInfoSection {
    color: var(--rnb-colour-midnight);

    @media (--rnb-mq-medium) {
        padding: 0;
    }
}

.cta {
    cursor: pointer;
    text-decoration: underline;
    font-weight: var(--rnb-font-weight-bold);
    color: var(--rnb-colour-chill);

    &:hover,
    &:focus {
        color: var(--rnb-colour-chill-tint-64);
    }
}

.drawer {
    width: 24rem;
}
