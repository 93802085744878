.navbar {
    --logo-width: 15.6rem;
    --logo-link-display: none;
    --logo-static-display: block;
    --border-bottom: none;
    --flex-direction: column-reverse;
    --link-align-self: flex-end;
    --links-margin: unset;
    --burger-icon-size: 2rem;
    --display-links: none;

    @media (--ds-mq-phablet) {
        --logo-width: 11.25rem;
        --flex-direction: row;
        --burger-icon-size: 2.5rem;
        --justify-nav-content: space-between;
        --display-burger-menu: inline;
        --display-links: none;
    }

    @media (--ds-mq-medium) {
        --logo-width: 11.25rem;
        --logo-link-display: block;
        --logo-static-display: none;
        --justify-nav-content: flex-start;
        --link-align-self: unset;
        --links-margin: 0 0 0 3.43rem;
        --border-bottom: var(--ds-border-width-thin) solid rgba(var(--ds-colour-white-rgb), 0.24);
        --display-links: flex;
        --display-burger-menu: none;
    }

    display: flex;
    flex-direction: var(--flex-direction);
    position: relative;
    width: 100%;
    justify-content: var(--justify-nav-content);
    align-items: center;
    z-index: var(--ds-zindex-levitate);
    padding: var(--ds-spacing-big) 0;
    border-bottom: var(--border-bottom);
}

.logoLink {
    display: var(--logo-link-display);
}

.logoStatic {
    display: var(--logo-static-display);
}

.logo {
    width: var(--logo-width);
    height: auto;
}

.displayLinks {
    display: var(--display-links);
    margin-left: var(--links-margin-left);
    width: 100%;
}

.displayBurgerMenu {
    display: var(--display-burger-menu);
    align-self: flex-end;
}

.burgerButton {
    border: none;
    background-color: var(--ds-colour-transparent);
    color: var(--ds-colour-white);
    cursor: pointer;
}

.burgerIcon {
    height: auto;
    width: var(--burger-icon-size);
}

.drawerContent {
    /* these values needs to be scoped here because
    this class is rendered inside react portal which is
    not rendered within the navigation element */
    --padding: 10rem 2.8rem 2rem 2.8rem;
    --background-colour: linear-gradient(165.43deg, rgb(30, 46, 124) 42.43%, rgb(149, 99, 162) 87.56%),
        linear-gradient(10deg, rgba(12, 18, 39, 0.5) 100%, rgba(12, 18, 39, 0.5) 100%);
    --links-colour: rgb(255, 255, 255, 0.6);

    width: 100%;
    background: var(--background-colour);
    background-blend-mode: multiply;
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.link {
    font-family: var(--ds-font-family-ds-averta);
    font-weight: var(--ds-font-weight-bold);
    font-size: var(--ds-font-size-mini);
    line-height: var(--ds-line-height-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--ds-colour-white);
    align-self: var(--link-align-self);
    margin: var(--links-margin);
    text-decoration: none;

    @media (--ds-mq-medium) {
        &:last-child {
            margin-left: auto;
        }
    }

    &:hover,
    &.selected {
        text-decoration: underline;
        text-underline-offset: var(--ds-spacing-nano);
        text-decoration-thickness: var(--ds-border-width-default);
    }

    &.burgerMenu {
        font-weight: normal;
        font-size: var(--ds-font-size-massive);
        line-height: var(--ds-line-height-jumbo);
        margin-bottom: var(--ds-spacing-big);
        color: var(--links-colour);
        text-decoration: none;

        &.selected {
            color: var(--ds-colour-white);
        }
    }
}
