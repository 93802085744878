.heroSection {
    --section-min-height: 38.62rem;
    --section-height: unset;
    --section-margin-bottom: 6.25rem;
    --subtitle-width: 34.5rem;
    --flex-direction: column;
    --justify-content: space-around;
    --header-text-align: center;
    --header-min-width: none;
    --header-margin: var(--ds-spacing-big) 0;
    --header-colour: rgb(100, 246, 255);
    --header-font-size: 2.37rem;
    --header-line-height: 1.16;
    --header-font-weight: var(--ds-font-weight-regular);
    --subheader-font-size: var(--ds-font-size-medium);
    --subheader-line-height: 1.4;
    --subheader-padding-top: var(--ds-spacing-small);

    @media (--ds-mq-small) {
        --header-font-size: var(--ds-font-size-huge);
        --section-min-height: 40.62rem;
        --header-margin: 5.5rem 0 7.5rem 0;
    }

    @media (--ds-mq-phablet) {
        --section-min-height: 51rem;
        --image-glitter-height: 75vh;
        --cta-display: inline;
        --header-text-align: center;
        --header-width: 30.6rem;
        --header-margin: 4.68rem 0;
        --header-font-size: 3.37rem;
        --header-font-weight: var(--ds-font-weight-semi-bold);
        --subheader-padding-top: var(--ds-spacing-medium);
    }

    @media (--ds-mq-large) {
        --section-height: 87vh;
        --header-width: 40rem;
        --header-text-align: left;
        --header-min-width: 25rem;
        --header-margin: 0 var(--ds-spacing-big) 0 0;
        --flex-direction: row;
        --justify-content: center;
        --header-font-size: 3.37rem;
    }

    @media (--ds-mq-huge) {
        --header-margin: 0 7rem 0 0;
        --header-font-size: 4rem;
    }

    width: 100%;
    min-height: var(--section-min-height);
    height: var(--section-height);
    margin-bottom: var(--section-margin-bottom);
    display: flex;
    flex-direction: var(--flex-direction);
    justify-content: var(--justify-content);
    align-items: center;
}

.headerWrapper {
    width: var(--header-width);
    min-width: var(--header-min-width);
    display: flex;
    flex-direction: column;
    text-align: var(--header-text-align);
    margin: var(--header-margin);
    font-family: var(--ds-font-family-ds-averta);
    z-index: var(--ds-zindex-levitate);
    padding: var(--header-padding);
}

.header {
    font-size: var(--header-font-size);
    font-weight: var(--header-font-weight);
    line-height: var(--header-line-height);
}

.subheader {
    padding-top: var(--subheader-padding-top);
    max-width: var(--subtitle-width);
    font-size: var(--subheader-font-size);
    font-weight: var(--ds-font-weight-light);
    line-height: var(--subheader-line-height);
    color: var(--ds-colour-secondary);
}

.heroGlitter {
    position: absolute;
    width: 100%;
    min-height: var(--image-glitter-min-height);
    height: var(--image-glitter-height);
    top: 0;
    left: 0;
    opacity: 0.6;
}
