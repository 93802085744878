.productDetail {
    --imageWidth: 112px;
    --imageHeight: 64px;

    @media (--rnb-mq-medium) {
        --imageWidth: 152px;
        --imageHeight: 87px;
    }

    color: var(--rnb-colour-midnight);

    & + * {
        margin-top: var(--rnb-spacing-big);
    }
}

.content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: var(--rnb-spacing-tiny);

    @media (--rnb-mq-medium) {
        margin-top: var(--rnb-spacing-medium);
        flex-direction: row;
    }
}

.imageContainer {
    width: var(--imageWidth);
    height: var(--imageHeight);
    margin-bottom: var(--rnb-spacing-tiny);
}

.description {
    flex-direction: column;
    color: var(--rnb-colour-midnight-tint-64);
    line-height: var(--rnb-line-height-text);

    &.hasImage {
        @media (--rnb-mq-medium) {
            width: 50%;
        }
    }
}

.ratingsDetails {
    @media (--rnb-mq-large) {
        max-width: 68%; /* inline with tge triple-feature-grid */
    }
}
