:root .loader {
    --loader-size-tiny: 1rem;
    --loader-size-small: 1.25rem;
    --loader-size-medium: 1.5rem;
    --loader-size-large: 1.87rem;
    --circle-stroke-width: 10;
    --stroke-empty: 270;
    --stroke-full: 540;
    --stroke-full-reduce: 810;
    --dasharray-coverage: 270;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100%;
}

.isSizeTiny {
    height: var(--loader-size-tiny);
    width: var(--loader-size-tiny);
}

.isSizeSmall {
    height: var(--loader-size-small);
    width: var(--loader-size-small);
}

.isSizeMedium {
    height: var(--loader-size-medium);
    width: var(--loader-size-medium);
}

.isSizeLarge {
    height: var(--loader-size-large);
    width: var(--loader-size-large);
}

@keyframes circle--animation {
    0% {
        stroke-dashoffset: var(--stroke-full-reduce);
    }

    63%,
    65% {
        stroke-dashoffset: var(--stroke-full);
    }

    100% {
        stroke-dashoffset: var(--stroke-empty);
    }
}

.gradient {
    transform-origin: 50% 50%;
    transform: rotate(45deg);
}

.circle {
    display: block;
    fill: var(--ds-colour-transparent);
    stroke-width: var(--circle-stroke-width);
    stroke-linecap: butt;
    stroke-dasharray: var(--dasharray-coverage);
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
    animation: circle--animation 1s infinite ease-in-out;
}

.circleInprint {
    fill: var(--ds-colour-transparent);
    stroke: var(--ds-colour-secondary-alpha-8);
    stroke-width: var(--circle-stroke-width);
}

.stopColourStart {
    stop-color: var(--ds-colour-highlight-green);
}

.stopColourEnd {
    stop-color: var(--ds-colour-highlight-pink);
}
