.themeLight {
    --theme-background-color: var(--rnb-colour-white);
    --theme-color: var(--rnb-colour-midnight);
}

.themeDark {
    --theme-background-color: var(--rnb-colour-midnight-alpha-96);
    --theme-color: var(--rnb-colour-white);
}

.placementRight {
    --placement-top: 0;
    --placement-right: 0;
    --placement-width: 100vw;
    --placement-min-height: 100%;
    --placement-transform: translateX(100%);
    --placement-box-shadow: var(--rnb-shadow-hill-top);

    &.isOpenTransition {
        --placement-transform: translateX(0%);
    }

    @media (--rnb-mq-medium) {
        --placement-width: auto;
    }
}

.placementLeft {
    --placement-top: 0;
    --placement-left: 0;
    --placement-width: 100vw;
    --placement-min-height: 100%;
    --placement-transform: translateX(-100%);
    --placement-box-shadow: var(--rnb-shadow-hill-top);

    &.isOpenTransition {
        --placement-transform: translateX(0%);
    }

    @media (--rnb-mq-medium) {
        --placement-width: auto;
    }
}

.placementBottom {
    --placement-bottom: 0;
    --placement-right: 0;
    --placement-left: 0;
    --placement-width: 100vw;
    --placement-transform: translateY(100%);
    --placement-box-shadow: var(--rnb-shadow-hill-top);

    &.isOpenTransition {
        --placement-transform: translateY(0%);
    }
}

/* drawerContent is not within .drawer due to react-portals */
.drawerContent {
    /* force mobile device to animate from the bottom */

    @media (--rnb-mq-lt-medium) {
        --placement-bottom: 0;
        --placement-right: 0;
        --placement-left: 0;
        --placement-width: 100vw;
        --placement-min-height: 100%;
        --placement-transform: translateY(100%);
        --placement-box-shadow: var(--rnb-shadow-hill-top);

        &.isOpenTransition {
            --placement-transform: translateY(0%);
        }
    }

    z-index: var(--rnb-zindex-top);
    position: fixed;
    height: 100%; /* fix scrolling on firefox */
    max-width: 100vw;
    max-height: 100vh;
    max-height: stretch; /* mobile viewport bug fix */
    overflow: auto;
    transition: transform var(--rnb-timing-fast) ease-in-out;
    display: flex; /* to allow content to easily be centered + full height */

    /* theme */
    background-color: var(--theme-background-color);
    color: var(--theme-color);

    /* placement */
    bottom: var(--placement-bottom, unset);
    top: var(--placement-top, unset);
    left: var(--placement-left, unset);
    right: var(--placement-right, unset);
    width: var(--placement-width, unset);
    min-height: var(--placement-min-height, unset);
    transform: var(--placement-transform);
    box-shadow: var(--placement-box-shadow);
}

.close {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: var(--rnb-spacing-medium);
    top: var(--rnb-spacing-medium);
    z-index: var(--rnb-zindex-top);
    color: currentColor;

    &:focus {
        color: currentColor;
    }
}

.backdrop {
    z-index: var(--rnb-zindex-top);
    position: fixed;
    inset: 0;
    background-color: var(--rnb-colour-black-alpha-64);
    visibility: hidden;
    opacity: 0;
    transition: visibility var(--rnb-timing-fast) ease-in-out, opacity var(--rnb-timing-fast) ease-in-out;

    &.isOpenTransition {
        opacity: 1;
        visibility: visible;
    }
}
