.component {
    position: relative;
    z-index: 1;
    grid-column-start: 1;
    grid-column-end: -1;
    background-color: var(--rnb-colour-black-tint-4);
}

.spanFullWidth {
    grid-column-start: 1;
    grid-column-end: -1;
    white-space: pre-line;
}

.cardContainer {
    width: 800px;
}

.product {
    list-style: none;
    border-radius: var(--rnb-border-radius-medium);
    overflow: hidden;
    box-shadow: var(--rnb-shadow-hill-light);

    @media (--rnb-mq-large) {
        box-shadow: var(--rnb-shadow-earth);
    }
}
