:root .component {
    --long-prepend-small-padding: 2.5rem;
}

.component {
    position: relative;
}

.prependLabel {
    pointer-events: none;
    position: absolute;
    padding-left: var(--rnb-spacing-small);
}

.select {
    font-family: var(--rnb-font-family-cs-clarity);
    font-weight: var(--rnb-font-weight-regular);
    font-size: var(--rnb-font-size-small);
    line-height: var(--rnb-line-height-text);
    margin: var(--rnb-spacing-tiny) var(--rnb-spacing-none);
    border-radius: var(--rnb-border-radius-medium);
    padding-left: var(--rnb-spacing-small);
    padding-right: var(--rnb-spacing-large);
    box-sizing: border-box;
    width: 100%;
    appearance: none;

    &.isSizeTiny {
        padding-top: var(--rnb-spacing-micro);
        padding-bottom: var(--rnb-spacing-micro);
    }

    &.isSizeSmall {
        padding-top: var(--rnb-spacing-tiny);
        padding-bottom: var(--rnb-spacing-tiny);
    }

    &.isSizeLarge {
        padding-top: var(--rnb-spacing-small);
        padding-bottom: var(--rnb-spacing-small);
    }

    &.isSizeSmall,
    &.isSizeTiny {
        font-size: var(--rnb-font-size-tiny);
    }

    &:hover {
        cursor: pointer;
    }

    &.isThemeLight {
        background-color: var(--rnb-colour-midnight-tint-4);
        border: var(--rnb-border-width-thin) solid var(--rnb-colour-midnight-tint-48);
        color: var(--rnb-colour-midnight);

        &.isLightBg {
            background-color: var(--rnb-colour-white);
        }

        &.isPlaceholder {
            color: var(--rnb-colour-midnight-tint-48);
        }

        & + .chevron {
            color: var(--rnb-colour-midnight);
        }

        &.isValid {
            border-color: var(--rnb-colour-sea-shade-64);
        }

        &.isInvalid {
            border-color: var(--rnb-colour-sand);
        }

        &.isDisabled {
            border-color: var(--rnb-colour-sky);
            color: var(--rnb-colour-midnight-tint-16);
            pointer-events: none;

            & + .chevron {
                color: var(--rnb-colour-midnight-tint-16);
            }
        }

        &:not([disabled], .isValid, .isInvalid):focus {
            border-color: var(--rnb-colour-midnight);
        }
    }

    &.isThemeDark {
        background-color: var(--rnb-colour-white-alpha-8);
        border: var(--rnb-border-width-thin) solid var(--rnb-colour-white-alpha-48);
        color: var(--rnb-colour-white-alpha-64);

        &.isLightBg {
            background-color: var(--rnb-colour-black-alpha-64);
        }

        & + .chevron {
            color: var(--rnb-colour-white-alpha-64);
        }

        &.isPlaceholder {
            color: var(--rnb-colour-white-alpha-48);
        }

        &.isValid {
            border-color: var(--rnb-colour-sea);
        }

        &.isInvalid {
            border-color: var(--rnb-colour-sand);
        }

        &.isDisabled {
            border-color: var(--rnb-colour-white-alpha-48);
            color: var(--rnb-colour-white-alpha-16);
            pointer-events: none;

            & + .chevron {
                color: var(--rnb-colour-white-alpha-16);
            }
        }

        &:not([disabled], .isValid, .isInvalid):focus {
            border-color: var(--rnb-colour-white);
        }
    }
}

.hasPrepend {
    font-weight: var(--rnb-font-weight-bold);
}

.shortPrepend {
    padding-left: var(--rnb-spacing-large);
}

.mediumPrepend {
    padding-left: var(--rnb-spacing-big);
}

.longPrepend {
    padding-left: var(--rnb-spacing-huge);

    &.isSizeSmall,
    &.isSizeTiny {
        padding-left: var(--long-prepend-small-padding);
    }
}

.chevron {
    position: absolute;
    right: var(--rnb-spacing-small);
    display: flex;
    align-items: center;
    height: 100%;
    pointer-events: none;
}

.wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.errorMessage {
    position: absolute;
    left: var(--rnb-spacing-small);
}
