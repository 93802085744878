.title {
    line-height: var(--rnb-line-height-text);
    font-size: var(--rnb-font-size-big);

    @media (--rnb-mq-large) {
        width: 50%;
        font-size: var(--rnb-font-size-huge);
    }
}

.asterisk {
    font-size: var(--rnb-font-size-large);
    vertical-align: super;
}

.subtitle {
    line-height: var(--rnb-line-height-text);

    @media (--rnb-mq-large) {
        width: 40%;
        font-size: var(--rnb-font-size-large);
        font-weight: var(--rnb-font-weight-regular);
    }
}

.listItem {
    display: flex;
    align-items: flex-start;
    margin-left: 0 !important;

    &:not(:first-child) {
        margin-top: var(--rnb-spacing-medium);
    }
}

.listItemWrapper {
    padding-top: 3px;
}

.listItem::before {
    display: none;
}

.component {
    position: relative;
    z-index: 1;
    grid-column-start: 1;
    grid-column-end: -1;
    white-space: pre-line;

    button {
        min-width: 250px !important;
    }

    small {
        display: block;
        text-align: center;

        @media (--rnb-mq-medium) {
            text-align: left;
        }
    }
}

.cardsWrapper {
    @media (--rnb-mq-large) {
        position: absolute;
        left: 40%;
        top: 50%;
        height: 50%;
        transform: translateY(-50%) scale(0.75);
        transform-origin: 100% 50%;
        right: 0;
        pointer-events: none;
        overflow: hidden;
    }
}

.card {
    height: 100%;
    aspect-ratio: 1.35;
}

.spanFullWidth {
    grid-column-start: 1;
    grid-column-end: -1;
}
