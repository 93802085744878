.drivescoreLandingBackground {
    --background-colour: rgb(9, 19, 47);
    --top-background-colour: linear-gradient(165.43deg, rgb(30, 46, 124) 42.43%, rgb(149, 99, 162) 87.56%);
    --bottom-background-colour: linear-gradient(
        180deg,
        rgba(17, 28, 62, 0) 0%,
        rgba(17, 28, 62, 0.2) 25%,
        rgba(17, 28, 62, 0.4) 40%,
        rgb(9, 19, 47) 80%
    );
    --top-gradient-min-height: 31rem;
    --top-gradient-height: 70vh;
    --content-padding: 0 var(--ds-spacing-big) var(--ds-spacing-super) var(--ds-spacing-big);

    @media (--ds-mq-phablet) {
        --top-gradient-height: 75vh;
    }

    @media (--ds-mq-large) {
        --top-gradient-min-height: 50rem;
        --top-gradient-height: 100vh;
        --content-padding: 0 4rem var(--ds-spacing-super) 4rem;
    }

    @media (--ds-mq-huge) {
        --content-padding: 0 5rem var(--ds-spacing-super) 5rem;
    }

    min-width: 100%;
    min-height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    background-color: var(--background-colour);
    color: var(--ds-colour-white);
}

.heroGradient {
    position: absolute;
    min-height: var(--top-gradient-min-height);
    height: var(--top-gradient-height);
    width: 100%;
    background: var(--top-background-colour);
}

.heroGradient::after {
    content: '';
    position: absolute;
    top: 80%;
    width: 100%;
    height: 21%;
    background: var(--bottom-background-colour);
    z-index: var(--ds-zindex-levitate);
}

.contentWrapper {
    padding: var(--content-padding);
    width: 100%;
    position: relative;
    overflow: hidden;
}

.trustpilotContainer {
    margin: 7rem auto 0;

    @media (--ds-mq-large) {
        margin: 12rem auto 0;
        max-width: 80rem;
    }
}

.desktopWidget {
    display: none;

    @media (--ds-mq-large) {
        display: block;
    }
}

.mobileWidget {
    display: block;

    @media (--ds-mq-large) {
        display: none;
    }
}
