.component {
    position: relative;
}

.label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: var(--rnb-spacing-micro);
    cursor: pointer;

    &.isThemeLight {
        color: var(--rnb-colour-midnight);

        &.disabled {
            color: var(--rnb-colour-midnight-tint-16);
        }
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);

        &.disabled {
            color: var(--rnb-colour-white-alpha-16);
        }
    }
}

.errorMessage {
    position: absolute;
    left: 4px;
}

.tickWrapper {
    position: absolute;
    left: 4px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    /* IE11 fix */
    flex-direction: row-reverse;
}

.tick {
    opacity: 0;
    margin-right: var(--rnb-spacing-medium);

    &.checked {
        opacity: 1;
    }

    &.isThemeLight {
        color: var(--rnb-colour-midnight);

        &.disabled {
            color: var(--rnb-colour-midnight-tint-16);
        }
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);

        &.disabled {
            color: var(--rnb-colour-white-alpha-16);
        }
    }
}

.disabled {
    pointer-events: none;
}

.input {
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: var(--rnb-spacing-tiny);
    border-radius: var(--rnb-border-radius-small);

    /* IE11 fallback */
    &[type='checkbox']::-ms-check {
        border-radius: var(--rnb-border-radius-small);
        color: var(--rnb-colour-transparent);
    }

    &:focus {
        outline: 2px solid var(--rnb-colour-chill);
        outline: auto 5px -webkit-focus-ring-color;
    }

    &.isThemeLight {
        background-color: var(--rnb-colour-white);
        border: 1px solid var(--rnb-colour-midnight);

        &.disabled {
            border: 1px solid var(--rnb-colour-midnight-tint-16);
        }
    }

    &.isThemeDark {
        background-color: var(--rnb-colour-black-alpha-32);
        border: 1px solid var(--rnb-colour-white);

        &.disabled {
            border: 1px solid var(--rnb-colour-white-alpha-16);
        }
    }
}
