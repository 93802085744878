:root {
    --icon-list-item-bullet-side-length: 32px;
    --logo-height: 24px;
}

.iconListItemBullet {
    width: var(--icon-list-item-bullet-side-length);
    height: var(--icon-list-item-bullet-side-length);
}

.logoListItemImage > img {
    max-height: var(--logo-height);
}
