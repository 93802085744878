.heroSection {
    --section-min-height: 38.62rem;
    --section-height: 87vh;
    --section-margin-top: var(--ds-spacing-large);
    --subtitle-width: 34.5rem;
    --image-min-width: 16.25rem;
    --image-max-width: 50rem;
    --image-width: 40vh;
    --text-align: center;
    --flex-direction: column-reverse;
    --justify-content: space-around;
    --text-min-width: none;
    --text-margin-right: 0;
    --button-height: 1.6rem;
    --image-glitter-height: 70vh;
    --image-glitter-min-height: 31rem;
    --prize-draw-button-margin: 0 auto;
    --promo-arrow-width: 4.6rem;
    --promo-arrow-height: 5.5rem;
    --cta-display: flex;
    --cta-order: -1;
    --cta-margin: var(--ds-spacing-medium) auto;
    --button-order: -2;
    --promo-arrow-order: -3;
    --promo-arrow-translate: translate(-1rem, 1rem);
    --promo-arrow-transform: rotate(225deg);
    --promo-arrow-position: absolute;
    --promo-arrow-top: -6rem;
    --promo-arrow-left: 15rem;
    --header-colour: rgb(100, 246, 255);

    @media (--ds-mq-small) {
        --section-min-height: 40.62rem;
        --section-margin-top: var(--ds-spacing-medium);
    }

    @media (--ds-mq-phablet) {
        --image-width: 45vh;
        --image-glitter-height: 75vh;
        --cta-display: inline;
    }

    @media (--ds-mq-large) {
        --image-min-width: 31.87rem;
        --image-width: 60vh;
        --text-width: 40rem;
        --text-align: left;
        --text-min-width: 29rem;
        --text-margin-right: -5rem;
        --flex-direction: row;
        --section-margin-top: 0;
        --justify-content: center;
        --image-glitter-height: 100vh;
        --image-glitter-min-height: 50rem;
        --prize-draw-button-margin: 0 0 var(--ds-spacing-large) 0;
        --promo-arrow-width: 8rem;
        --promo-arrow-height: 9.6rem;
        --cta-order: 0;
        --cta-margin: var(--ds-spacing-big) 0 0 0;
        --button-order: 0;
        --promo-arrow-order: 0;
        --promo-arrow-transform: translate(2rem, 1rem) rotate(252deg);
        --promo-arrow-top: -5rem;
        --promo-arrow-left: 20rem;
    }

    @media (--ds-mq-huge) {
        --text-margin-right: unset;
    }

    width: 100%;
    height: var(--section-height);
    min-height: var(--section-min-height);
    margin-top: var(--section-margin-top);
    display: flex;
    flex-direction: var(--flex-direction);
    justify-content: var(--justify-content);
    align-items: center;
}

.headerContent {
    display: flex;
    flex-direction: column;
    z-index: var(--ds-zindex-levitate);
    text-align: var(--text-align);
    width: var(--text-width);
    margin-top: var(--ds-spacing-big);
    margin-right: var(--text-margin-right);
    min-width: var(--text-min-width);
}

.subheader {
    padding-top: var(--ds-spacing-medium);
    max-width: var(--subtitle-width);
}

.cta {
    display: var(--cta-display);
    position: relative;
    justify-content: center;
    margin: var(--cta-margin);
    order: var(--cta-order);
}

.image {
    min-width: var(--image-min-width);
    max-width: var(--image-max-width);
    width: var(--image-width);
}

.heroGlitter {
    position: absolute;
    width: 100%;
    min-height: var(--image-glitter-min-height);
    height: var(--image-glitter-height);
    top: 0;
    left: 0;
    opacity: 0.6;
}

.cianoText {
    color: var(--header-colour);
}

.promoArrow {
    position: absolute;
    top: var(--promo-arrow-top);
    left: var(--promo-arrow-left);
    width: var(--promo-arrow-width);
    height: var(--promo-arrow-height);
    transform: var(--promo-arrow-transform);
}

.trustPilotWidgetContainer {
    position: relative;
}

.trustPilotWidget {
    position: absolute;
    margin-top: 2.5rem;
    width: 100%;

    @media (--ds-mq-large) {
        display: block;
        position: relative;
        margin-left: -1.5rem;
        max-width: 420px;
    }
}
