:root .autoSuggest {
    --autosuggest-height: 3.3125rem;
}

.autoSuggest {
    position: relative;

    & .loader {
        position: absolute;
        right: 48px;
        top: 25px;
        color: var(--rnb-colour-midnight);

        &.isThemeDark {
            color: var(--rnb-colour-white);
        }
    }

    & input {
        padding-right: var(--rnb-spacing-huge);
        text-overflow: ellipsis;
    }

    & .close {
        position: absolute;
        border: none;
        padding: 0;
        background-color: var(--rnb-colour-transparent);
        right: var(--rnb-spacing-small);
        top: 0;
        display: flex;
        align-items: center;
        height: 100%;
        color: var(--rnb-colour-midnight-tint-48);
        cursor: pointer;

        &.isThemeDark {
            color: var(--rnb-colour-white-alpha-48);
        }
    }
}

.suggestionWrapper {
    top: calc(var(--autosuggest-height) + var(--rnb-spacing-medium));
    left: 0;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    z-index: var(--rnb-zindex-levitate);
    background-color: var(--rnb-colour-white);
    padding: var(--rnb-spacing-tiny) var(--rnb-spacing-none);
    border-radius: var(--rnb-border-radius-medium);
    box-shadow: var(--rnb-shadow-hill-light);

    &.isThemeDark {
        background-color: var(--rnb-colour-midnight-shade-48);
    }

    &.hasNoSuggestions {
        padding-bottom: var(--rnb-spacing-huge);

        & .suggestionItem:last-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    & .additionalSuggestion,
    & .requiredMessage {
        padding: var(--rnb-spacing-small) var(--rnb-spacing-medium);
        box-sizing: border-box;
        width: 100%;
        border-top: 0;
        position: absolute;
        color: var(--rnb-colour-midnight);
        bottom: var(--rnb-spacing-tiny);
        left: 0;

        &.isThemeDark {
            color: var(--rnb-colour-white);
        }
    }

    & .additionalSuggestion {
        cursor: pointer;

        &:hover {
            background-color: var(--rnb-colour-midnight-tint-4);

            &.isThemeDark {
                background-color: var(--rnb-colour-white-alpha-16);
            }
        }
    }

    & .suggestionList {
        max-height: 180px;
        overflow-y: auto;

        & .suggestionItem {
            color: var(--rnb-colour-midnight);
            padding: var(--rnb-spacing-small) var(--rnb-spacing-medium);
            box-sizing: border-box;
            width: 100%;
            border-top: 0;
            cursor: pointer;

            &.isThemeDark {
                color: var(--rnb-colour-white);
            }

            &:hover {
                background-color: var(--rnb-colour-midnight-tint-4);

                &.isThemeDark {
                    background-color: var(--rnb-colour-white-alpha-16);
                }
            }

            & span {
                font-weight: var(--rnb-font-weight-bold);
            }
        }
    }
}

.suggestionSelected {
    background-color: var(--rnb-colour-midnight-tint-4);

    &.isThemeDark {
        background-color: var(--rnb-colour-white-alpha-16);
    }
}
