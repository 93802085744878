.heroRatingStarsInline,
.heroRatingStars {
    display: flex;
    align-items: center;
    color: var(--rnb-colours-midnight);
}

.heroRatingStars {
    flex-direction: column;
}

.countLabel {
    color: var(--rnb-colour-midnight-tint-64);
}
