.howItWorksSection {
    --max-width-section: 68.75rem;
    --max-width-text: 30rem;
    --item-margin: 5rem;
    --flex-direction: column-reverse;
    --text-align: center;
    --max-image-size: 25rem;

    @media (--ds-mq-large) {
        --flex-direction: row;
        --text-align: unset;
    }

    width: 100%;
    max-width: var(--max-width-section);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.howItWorksItem {
    display: flex;
    flex-direction: var(--flex-direction);
    justify-content: space-between;
    align-items: center;
    text-align: var(--text-align);
    width: 100%;
    margin-top: var(--item-margin);
}

.sectionText {
    max-width: var(--max-width-text);
    padding-top: var(--ds-spacing-large);

    @media (--ds-mq-large) {
        margin-right: 4rem;
    }
}

.sectionTextBody {
    padding-top: var(--ds-spacing-large);
    color: var(--ds-colour-secondary);
}

.image {
    width: 100%;
    height: 100%;
    max-width: var(--max-image-size);
    transform-origin: center center;
    transform: scale(var(--scaleValue, 1.8));
}
