.button {
    --min-width: 8rem;
    --icon-size: 1.56rem;
    --icon-size-small: 1.25rem;
    --max-height-medium: 3rem;
    --max-height-small: 2.25rem;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--ds-border-radius-medium);
    min-width: var(--min-width);
    color: var(--ds-colour-white);
    padding: 0 var(--ds-spacing-generous);
    font-family: var(--ds-font-family-ds-averta);
    font-weight: var(--ds-font-weight-semi-bold);
    font-size: var(--ds-font-size-small);

    &.fullWidth {
        width: 100%;
    }

    &.upperCase {
        font-size: var(--ds-font-size-mini);
        text-transform: uppercase;
    }

    &.size_SMALL {
        padding: var(--ds-spacing-small) var(--ds-spacing-tiny);
        height: var(--max-height-small);
        min-width: unset;
        font-size: var(--ds-font-size-micro);
        letter-spacing: 0.0875rem;

        @media (--ds-mq-small) {
            font-size: var(--ds-font-size-tiny);
            padding: var(--ds-spacing-small);
        }
    }

    &.size_MEDIUM {
        height: var(--max-height-medium);
        padding: var(--ds-spacing-medium) var(--ds-spacing-tiny);

        @media (--ds-mq-small) {
            padding: var(--ds-spacing-medium);
        }
    }

    &.withIcon {
        padding: var(--ds-spacing-small);
    }

    &.type_PRIMARY {
        background-color: var(--ds-colour-button-primary);

        &:hover,
        &:visited,
        &:active {
            background-color: var(--ds-colour-button-primary-pressed);
        }

        &.disabled {
            background-color: var(--ds-colour-button-primary-disabled);
            color: var(--ds-colour-secondary);
        }
    }

    &.type_SECONDARY {
        background-color: var(--ds-colour-transparent);
        border: var(--ds-border-width-thin) solid var(--ds-colour-white);

        &:hover,
        &:visited,
        &:active {
            background-color: var(--ds-colour-secondary-alpha-33);
        }

        &.disabled {
            border-color: var(--ds-colour-secondary-alpha-33);
            background-color: var(--ds-colour-transparent);
            color: var(--ds-colour-secondary-alpha-33);
        }
    }

    &.type_TERTIARY {
        background-color: var(--ds-colour-transparent);

        &:hover,
        &:visited,
        &:active {
            background-color: var(--ds-colour-secondary-alpha-33);
        }

        &.disabled {
            background-color: var(--ds-colour-transparent);
            color: var(--ds-colour-secondary);
        }
    }
}

.icon {
    width: var(--icon-size);
    height: var(--icon-size);
    margin-right: var(--ds-spacing-small);

    &.type_TERTIARY {
        width: var(--icon-size-small);
        height: var(--icon-size-small);
    }

    &.size_SMALL {
        width: var(--icon-size-small);
        height: var(--icon-size-small);
        margin-right: var(--ds-spacing-tiny);
    }
}
