.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--rnb-spacing-tiny);

    &.isThemeLight {
        color: var(--rnb-colour-midnight);
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);
    }
}

.description {
    &.isThemeLight {
        color: var(--rnb-colour-midnight-tint-64);
    }

    &.isThemeDark {
        color: var(--rnb-colour-white-alpha-64);
    }
}
