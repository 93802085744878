.findOutMore {
    display: flex;

    .titleWrapper {
        display: flex;
        flex-direction: row;
        align-items: end;
        font-size: 18px;
        line-height: 22px;
    }
}

.content {
    background: var(--rnb-colour-reef-shade-48);
    overflow-x: hidden;
    color: var(--rnb-colour-white);
    width: 100vw;
    max-width: 652px;
    border-radius: var(--rnb-border-radius-large);
    background-size: cover;
    height: 90vh;
    max-height: 650px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;

    @media (--rnb-mq-large) {
        max-height: 700px;
    }

    picture,
    img {
        min-width: 100%;
        height: 100%;
    }

    &.midnight {
        background: var(--rnb-colour-midnight);
    }
}

.contentInner {
    position: absolute;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    padding: var(--rnb-spacing-large);
    width: 90%;
    background: var(--rnb-colour-black-alpha-32);
    backdrop-filter: blur(24px);
    border-radius: var(--rnb-border-radius-small);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);

    @media (--rnb-mq-medium) {
        width: 76%;
        padding: var(--rnb-spacing-big) var(--rnb-spacing-super);
    }
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: end;
    font-size: 18px;
    line-height: 22px;
    flex-wrap: nowrap;
}

.featureListDescription {
    margin-top: var(--rnb-spacing-big);
    margin-bottom: var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        padding: 0 var(--rnb-spacing-super);
    }
}
.listWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    svg {
        color: var(--rnb-colour-white);
        border-radius: var(--rnb-border-radius-circle);
    }
}
.listItem {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.subtitle {
    text-align: left;
    font-size: 14px;
}

.richDescription {
    text-align: left;

    @media (--rnb-mq-large) {
        padding: 0 var(--rnb-spacing-big);
    }
}

.cta {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
}

.richDescriptionCta {
    cursor: pointer;
    text-decoration: underline;
    text-align: left;
    align-items: center;
    color: var(--rnb-colour-reef-shade-64);
    font-size: var(--rnb-font-size-tiny);
    font-weight: var(--rnb-font-weight-bold);
    padding-left: var(--rnb-spacing-micro);
    padding-bottom: var(--rnb-spacing-micro);
    background: none;

    &:hover,
    &:focus {
        color: var(--rnb-colour-chill-tint-64);
    }
}