.clickableItem {
    display: inherit;
    border: 0;
    cursor: pointer;
    height: fit-content;
    padding: 0;
    width: fit-content;
    text-align: left;
    text-decoration: none;

    &.disabled {
        cursor: default;
    }

    &.isFullWidth {
        width: 100%;
    }

    &:active {
        -webkit-tap-highlight-color: var(--ds-colour-transparent);
    }

    @media (--ds-mq-phablet) {
        &:focus {
            outline: auto;
            border-radius: var(--ds-border-radius-medium);
        }
    }
}
