.infoTable {
    border: solid var(--rnb-border-width-thin) var(--rnb-colour-midnight-tint-16);
    border-radius: var(--rnb-border-radius-medium);
    overflow: hidden;

    @media (--rnb-mq-large) {
        max-width: 68%; /* inline with the triple-feature-grid */
    }
}

.highlighted {
    border-color: var(--rnb-colour-reef-shade-48);

    .header {
        background-color: var(--rnb-colour-reef-shade-48);
        color: var(--rnb-colour-white);
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: var(--rnb-colour-chill-tint-16);
    padding: var(--rnb-spacing-medium);
    color: var(--rnb-colour-midnight);
}

.guaranteed {
    color: var(--rnb-colour-reef-shade-32);
}

.infoIcon {
    margin-top: calc(var(--rnb-spacing-micro) * -1);
}
