:root .drawer {
    --drawer-width: 100%;

    @media (--rnb-mq-medium) {
        --drawer-width: 504px;
    }
}

.drawer {
    height: 100%;
    width: var(--drawer-width);
    padding: var(--rnb-spacing-huge) var(--rnb-spacing-big) var(--rnb-spacing-big);
    overflow-y: auto;
    color: var(--rnb-colour-midnight);
    background-color: var(--rnb-colour-white);
}
