.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.preWrap {
    white-space: pre-wrap;
}

.fullWidth {
    grid-column: 1 / -1;
}

.colorMidnight {
    color: var(--rnb-colour-midnight);
}
