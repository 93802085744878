.appPreviewSection {
    --max-section-width: 78.75rem;
    --min-image-width: 20rem;
    --max-image-width: 35rem;
    --image-scale: scale(1.6);
    --flex-direction: column-reverse;
    --section-margin: var(--ds-spacing-jumbo) auto var(--ds-spacing-jumbo) auto;
    --text-max-width: 18rem;
    --text-top-margin: unset;
    --text-align: center;

    @media (--ds-mq-large) {
        --image-scale: scale(1.8);
        --flex-direction: row;
        --section-margin: calc(var(--ds-spacing-jumbo) * 2) auto 0 auto;
        --text-align: unset;
        --text-max-width: 35rem;
        --text-top-margin: -4rem;
    }

    width: 100%;
    max-width: var(--max-section-width);
    margin: var(--section-margin);
    display: flex;
    flex-direction: var(--flex-direction);
    align-items: center;
    justify-content: space-between;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: var(--text-max-width);
    text-align: center;
    margin-top: var(--text-top-margin);
    z-index: var(--ds-zindex-levitate);

    @media (--ds-mq-large) {
        text-align: left;
        display: block;
        align-items: start;
        margin-top: 0;
    }
}

.header {
    text-align: var(--text-align);
}

.storeButtons {
    display: flex;
    padding-top: var(--ds-spacing-big);

    @media (--ds-mq-large) {
        padding-top: var(--ds-spacing-huge);
    }
}

.image {
    width: 100%;
    height: 100%;
    min-width: var(--min-image-width);
    max-width: var(--max-image-width);
    transform-origin: center center;
    transform: var(--image-scale);
}
