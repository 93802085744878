.text {
    --ds-colour-inherit: inherit;

    font-family: var(--ds-font-family-ds-averta);
    color: var(--ds-colour-inherit);
    letter-spacing: 0.03rem;

    /* THEMES */
    &.isThemePositive {
        color: var(--ds-colour-highlight-green);
    }

    &.isThemeWarning {
        color: var(--ds-colour-highlight-pink);
    }

    &.isThemeLight {
        color: var(--ds-colour-white);
    }

    &.isThemeCaution {
        color: var(--ds-colour-caution);
    }

    /* SIZES */
    &.isSizeMicro {
        font-size: var(--ds-font-size-micro);
        line-height: var(--ds-line-height-text);
    }

    &.isSizeTiny {
        font-size: var(--ds-font-size-tiny);
        line-height: var(--ds-line-height-text);
    }

    &.isSizeMini {
        font-size: var(--ds-font-size-mini);
        line-height: var(--ds-line-height-text);
    }

    &.isSizeSmall {
        font-size: var(--ds-font-size-small);
        line-height: var(--ds-line-height-text);
    }

    &.isSizeMedium {
        font-size: var(--ds-font-size-medium);
        line-height: var(--ds-line-height-heading);
    }

    &.isSizeAdequate {
        font-size: var(--ds-font-size-adequate);
        line-height: var(--ds-line-height-heading);
    }

    &.isSizeLarge {
        font-size: var(--ds-font-size-large);
        line-height: var(--ds-line-height-heading);
    }

    &.isSizeBig {
        font-size: var(--ds-font-size-big);
        line-height: var(--ds-line-height-heading);
    }

    &.isSizeHuge {
        font-size: var(--ds-font-size-huge);
        line-height: var(--ds-line-height-heading);
    }

    &.isSizeMassive {
        font-size: var(--ds-font-size-massive);
        line-height: var(--ds-line-height-heading);
    }

    &.isSizeJumbo {
        font-size: var(--ds-font-size-jumbo);
        line-height: var(--ds-line-height-heading);
    }

    /* LINE HEIGHT */

    /* TO BE DEPRECATED CS-103094 */
    &.isLineHeightSmall {
        line-height: var(--ds-line-height-small);
    }

    &.isLineHeightAdeguate {
        line-height: var(--ds-line-height-adeguate);
    }

    &.isLineHeightMedium {
        line-height: var(--ds-line-height-medium);
    }

    &.isLineHeightGenerous {
        line-height: var(--ds-line-height-generous);
    }

    &.isLineHeightLarge {
        line-height: var(--ds-line-height-large);
    }

    &.isLineHeightGreat {
        line-height: var(--ds-line-height-great);
    }

    &.isLineHeightBig {
        line-height: var(--ds-line-height-big);
    }

    &.isLineHeightHuge {
        line-height: var(--ds-line-height-huge);
    }

    &.isLineHeightMassive {
        line-height: var(--ds-line-height-massive);
    }

    &.isLineHeightJumbo {
        line-height: var(--ds-line-height-jumbo);
    }

    &.isLineHeightGigantic {
        line-height: var(--ds-line-height-gigantic);
    }

    /* WEIGHTS */
    &.isWeightExtrathin {
        font-weight: var(--ds-font-weight-extra-thin);
    }

    &.isWeightThin {
        font-weight: var(--ds-font-weight-thin);
    }

    &.isWeightLight {
        font-weight: var(--ds-font-weight-light);
    }

    &.isWeightRegular {
        font-weight: var(--ds-font-weight-regular);
    }

    &.isWeightMedium {
        font-weight: var(--ds-font-weight-medium);
    }

    &.isWeightSemibold {
        font-weight: var(--ds-font-weight-semi-bold);
    }

    &.isWeightBold {
        font-weight: var(--ds-font-weight-bold);
    }

    &.isWeightExtrabold {
        font-weight: var(--ds-font-weight-extra-bold);
    }

    &.isWeightBlack {
        font-weight: var(--ds-font-weight-black);
    }

    /* SPACINGS */
    &.isSpacingWide {
        letter-spacing: 0.0063rem;
    }

    &.isSpacingExploded {
        letter-spacing: 0.0875rem;
    }

    &.isItalic {
        font-style: italic;
    }
}
