.stepsSection {
    --maxContainerSize: 848px;
    --padding-vertical: var(--rnb-spacing-big);

    @media (--rnb-mq-large) {
        --padding-vertical: var(--rnb-spacing-super);
    }

    padding: var(--padding-vertical) var(--rnb-spacing-large);
    color: var(--rnb-colour-midnight);
    width: 100%;
    max-width: var(--maxContainerSize);
    margin: 0 auto;
}

.steps {
    --display: block;
    --grid-gap: normal;
    --margin-bottom: 40px;
    --grid-template-columns: none;
    --text-align: left;

    @media (--rnb-mq-large) {
        --margin-bottom: var(--rnb-spacing-super);
        --display: grid;
        --grid-template-columns: repeat(3, 1fr);
        --grid-gap: 40px;
        --text-align: center;
    }

    display: var(--display);
    grid-template-columns: var(--grid-template-columns);
    gap: var(--grid-gap);
    text-align: var(--text-align);
    margin-bottom: var(--margin-bottom);
}

.step {
    --flex-direction: row;
    --justify-content: center;
    --align-items: center;
    --margin-bottom: 40px;

    @media (--rnb-mq-large) {
        --margin-bottom: 0;
        --flex-direction: column;
        --justify-content: flex-start;
        --align-items: flex-start;
    }

    display: flex;
    flex-direction: var(--flex-direction);
    align-items: var(--align-items);
    justify-content: var(--justify-content);
    margin-bottom: var(--margin-bottom);
}

.image {
    --image-size: 72px;
    --margin-bottom: 0;
    --margin-right: var(--rnb-spacing-small);

    @media (--rnb-mq-large) {
        --image-size: 124px;
        --margin-bottom: 28px;
        --margin-right: auto;
    }

    width: var(--image-size);
    height: var(--image-size);
    margin: 0 var(--margin-right) var(--margin-bottom) auto;
}

.text {
    flex: 1;
}

.cta {
    display: flex;
    justify-content: center;
}
