:root {
    --hero-box-width: 638px;
    --hero-img-height-small: 170px;
}

.hero {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
}

.heroBox {
    background-color: var(--rnb-colour-white);

    @media (--rnb-mq-medium) {
        margin: var(--rnb-spacing-huge) var(--rnb-spacing-large);
    }

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-large);
        border-radius: var(--rnb-border-radius-medium);
        width: 50%;
        margin: var(--rnb-spacing-super);
    }
}

.backgroundImage {
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: var(--hero-img-height-small);

    @media (--rnb-mq-medium) {
        height: 100%;
    }

    @media (--rnb-mq-large) {
        z-index: -1;
        position: absolute;
    }
}
