.container {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--rnb-spacing-small) var(--rnb-spacing-huge);
    white-space: pre-wrap;

    @media (--rnb-mq-medium) {
        grid-template-columns: repeat(12, 1fr);
    }
}

.image {
    align-self: flex-start;

    @media (--rnb-mq-medium) {
        grid-column: 1 / span 5;
    }
}

.gatsbyImage {
    object-fit: cover;

    @media (--rnb-mq-medium) {
        /* It's either this or using js to get object fit responsive with gatsby image [TC] */
        object-fit: contain !important;
    }
}

.text {
    @media (--rnb-mq-medium) {
        grid-column: span 7 / -1;
    }
}

.cardContainer {
    margin-top: var(--rnb-spacing-large);
}

.cardContainer:not(:last-child) {
    margin-bottom: var(--rnb-spacing-large);
}

.cardContainer + .cardContainer {
    margin-top: var(--rnb-spacing-big);
}
