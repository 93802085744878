.sectionInformationWithExample {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: var(--rnb-spacing-big);

    @media (--rnb-mq-medium) {
        width: 100%;
        flex-direction: row;
        flex-wrap: unset;
        gap: var(--rnb-spacing-huge);
    }
}

.isDark {
    color: var(--rnb-colour-white);
}

.isReversed {
    flex-direction: column-reverse;

    @media (--rnb-mq-medium) {
        flex-direction: row-reverse;
    }
}

.flex1 {
    flex: 1;
}
