.disclaimer {
    color: var(--rnb-colour-midnight);

    .disclaimerDescription {
        color: var(--rnb-colour-midnight-tint-64);
    }

    &.inline {
        color: var(--rnb-colour-midnight-tint-64);

        &.hasTitle {
            > :first-child {
                margin-right: var(--rnb-spacing-micro);

                &::after {
                    display: inline;
                    content: ':';
                }
            }
        }

        > * {
            display: inline;
        }
    }
}
