.rewardsTitle {
    color: var(--rnb-colour-midnight);
    font-size: var(--rnb-font-size-medium);
}

.titleContainer {
    display: flex;
    margin-bottom: var(--rnb-spacing-medium);
    justify-content: space-between;
}

.tableContainer {
    margin-bottom: var(--rnb-spacing-large);

    table {
        width: 100%;
    }

    td {
        vertical-align: middle;
    }
}

tr {
    border-bottom: solid 0.5px var(--rnb-colour-midnight-tint-16);
}

.headerSection {
    margin-bottom: var(--rnb-spacing-medium);
}

.rewardsDescription,
.labelText {
    color: var(--rnb-colour-midnight);
}

.table {
    width: 100%;
}

.td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.tdExtra {
    padding-top: 16px;
    padding-bottom: 16px;
}

.tdTopSpace {
    padding-bottom: 10px;
    padding-top: 26px;
}

.enlargedRow {
    padding-top: 6px;
    padding-bottom: 6px;
}

tr .td:nth-child(2),
.tdExtra:nth-child(2) div {
    display: flex;
    justify-content: flex-end;
}

.rewardValue {
    display: flex;
}

.rewardValueText {
    font-weight: var(--rnb-font-weight-bold);
}

.positiveTextValue,
.positiveSignValue {
    color: var(--rnb-colour-positive-light);
}

.negativeTextValue,
.negativeSignValue {
    color: var(--rnb-colour-negative-light);
}

.neutralTextValue {
    color: var(--rnb-colour-midnight);
}

.enlargenedText {
    font-size: var(--rnb-font-size-small);
}

.tipSection {
    display: flex;
    align-items: center;
    margin-bottom: var(--rnb-spacing-large);
}

.tipIcon {
    margin-right: var(--rnb-spacing-small);
}

.disclaimerSection {
    margin-bottom: var(--rnb-spacing-large);
}

.rewardsDisclaimer {
    color: var(--rnb-colour-midnight);
}

.helpInfoDrawer {
    width: 100vw;
    height: 100vh;
    padding: var(--drawer-top-padding-s) var(--rnb-spacing-large) var(--rnb-spacing-large);
    color: var(--rnb-colour-midnight);
    background-color: var(--rnb-colour-white);

    @media (--rnb-mq-medium) {
        width: var(--drawer-width-m);
        padding: var(--rnb-spacing-super) var(--rnb-spacing-large) var(--rnb-spacing-large);
    }
}

.helpInfoHeader {
    display: flex;
    justify-content: center;
    margin-bottom: var(--rnb-spacing-medium);
}

.gridItemRewards {
    grid-area: rewards;
}

.help {
    margin-right: var(--rnb-spacing-meidum);
}

.sign {
    align-items: center;
}
