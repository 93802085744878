:root {
    background-color: var(--rnb-colour-white);
}

.cards {
    position: relative;
    z-index: 1;
    grid-column-start: 1;
    grid-column-end: -1;
    color: var(--rnb-colour-midnight);
}

.sectionContainer {
    position: relative;
}
