.item {
    &:not(.isCondensed) {
        margin-bottom: var(--rnb-spacing-large);
    }

    &.isGroupedItem {
        margin-left: var(--rnb-spacing-tiny);
        flex-grow: 1;
    }
}

.divider {
    height: 1px;
    background-color: var(--rnb-colour-midnight-tint-16);
    margin-bottom: var(--rnb-spacing-large);
}

.group {
    display: flex;
    flex-direction: column;
    margin-left: calc(-1 * var(--rnb-spacing-tiny));

    @media (--rnb-mq-medium) {
        flex-direction: row;
    }

    &.keepInRow {
        flex-direction: row;
    }

    &.isWeightedLeft {
        & > .isGroupedItem:first-child {
            flex-grow: 1;

            @media (--rnb-mq-small) {
                flex-grow: 0;
            }
        }
    }

    &.isWeightedRight {
        & > .isGroupedItem:last-child {
            flex-grow: 1;

            @media (--rnb-mq-small) {
                flex-grow: 0;
            }
        }
    }
}
