.appDetailsSection {
    --max-section-width: 89.81rem;
    --max-width-text: 30rem;
    --section-top-margin: calc(var(--ds-spacing-jumbo) + var(--ds-spacing-large));
    --justify-content: space-between;
    --flex-direction: column-reverse;
    --flex-direction-reverse: column-reverse;
    --text-align: center;
    --text-padding: calc(var(--ds-spacing-super) + var(--ds-spacing-medium));
    --image-blur-value: 4rem;
    --image-top-position: -2.5rem;
    --image-left-position: 2.5rem;
    --image-radial-background: radial-gradient(
        57.3% 59.91% at left 60% top 14%,
        rgba(204, 0, 255, 0.5) 4.17%,
        rgba(0, 224, 255, 0.15) 90.18%
    );
    --ts-cs-font-size: var(--ds-font-size-tiny);

    @media (--ds-mq-large) {
        --max-width-text: 32.5rem;
        --flex-direction: row;
        --flex-direction-reverse: row-reverse;
        --section-top-margin: var(--ds-spacing-jumbo);
        --justify-content: space-around;
        --text-align: unset;
        --text-padding: unset;
        --image-blur-value: 7rem;
        --ts-cs-font-size: var(--ds-font-size-mini);
    }

    display: flex;
    width: 100%;
    margin: auto;
    max-width: var(--max-section-width);
    margin-top: var(--section-top-margin);
    flex-direction: var(--flex-direction);
    justify-content: var(--justify-content);
    align-items: center;
}

.reverse {
    flex-direction: var(--flex-direction-reverse);
}

.sectionText {
    max-width: var(--max-width-text);
    margin-top: var(--text-padding);
    text-align: var(--text-align);
}

.sectionTextBody {
    padding-top: var(--ds-spacing-large);
    color: var(--ds-colour-secondary);
}

.imageContainer {
    width: fit-content;
    height: 100%;
    position: relative;
    min-width: fit-content;
}

.gradientBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    top: var(--image-top-position);
    left: var(--image-left-position);
    background: var(--image-radial-background);
    filter: blur(var(--image-blur-value));
}

.tsCsText {
    padding-top: var(--ds-spacing-big);
    color: var(--ds-colour-secondary);
    font-size: var(--ts-cs-font-size);
    font-weight: var(--ds-font-weight-thin);
    font-family: var(--ds-font-family-ds-averta);
    line-height: var(--ds-line-height-adequate);
}
