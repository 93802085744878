:root .component {
    --accessory-right-top: var(--rnb-spacing-micro);
    --border-colour: var(--rnb-colour-midnight-tint-16);
}

.component {
    width: 100%;
    color: var(--rnb-colour-midnight);

    &.hasDescription {
        --accessory-right-top: var(--rnb-spacing-tiny);
    }

    &.SOLID {
        background-color: var(--rnb-colour-chill-tint-16);
        color: var(--rnb-colour-midnight);
    }

    &.HIGHLIGHTED {
        background-color: var(--rnb-colour-reef-shade-48);
        color: var(--rnb-colour-white);
    }

    &.HIGHLIGHTED_TINT {
        background-color: var(--rnb-colour-reef-tint-8);
    }

    &:not(:last-child) {
        &.rowDividers,
        .rowDividers {
            border-bottom: 1px solid var(--border-colour);
        }
    }
}

.content {
    display: flex;
    text-align: left;

    &.gutters {
        padding: 0 var(--rnb-spacing-medium);
    }
}

.text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
    padding-right: var(--rnb-spacing-small);

    & p {
        color: var(--rnb-colour-midnight-tint-64);
    }

    &.isFlipped p {
        order: 1;
    }

    &.isFlipped .titleWrapper {
        order: 2;
    }
}

.image + .textAndRightAccessory {
    margin-left: 0;
    padding: var(--rnb-spacing-large) 0;
}

.icon + .textAndRightAccessory {
    margin-left: 0;
    padding: var(--rnb-spacing-medium) 0;
}

.textAndRightAccessory {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
    margin-left: var(--rnb-spacing-none);
    padding: var(--rnb-spacing-medium) 0;
}

.narrowRow {
    padding: var(--rnb-spacing-small) 0;
}

.rightAccessory {
    padding-top: var(--accessory-right-top);
}

.borderBottom {
    border-bottom: 1px solid var(--border-colour);
    align-self: center;
}

.borderTop {
    border-top: 1px solid var(--border-colour);
}

.isLink {
    cursor: pointer;
    text-decoration: none;

    &:visited {
        color: var(--rnb-colour-midnight);
    }

    &:hover,
    &:focus {
        color: var(--rnb-colour-midnight-tint-64);
    }
}

.icon {
    width: 100%;
    height: 100%;
    max-width: var(--rnb-spacing-big);
    max-height: var(--rnb-spacing-big);
    position: relative;
    padding: var(--rnb-spacing-small) 0;
    margin-right: var(--rnb-spacing-medium);
}

.inlineIcon {
    margin-right: var(--rnb-spacing-tiny);
}

.rightIcon {
    width: 100%;
    height: 100%;
    max-width: var(--rnb-spacing-big);
    max-height: var(--rnb-spacing-big);
    padding: var(--rnb-spacing-tiny) 0;
    margin-right: var(--rnb-spacing-none);
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.image {
    width: 100%;
    height: 100%;
    max-width: var(--rnb-spacing-huge);
    max-height: var(--rnb-spacing-huge);
    position: relative;
    padding: var(--rnb-spacing-large) 0;
    margin-right: var(--rnb-spacing-medium);
}
