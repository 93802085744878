.container {
    width: 100%;
}

.list {
    max-width: 350px;
    list-style-position: inside;
}

.listBlocks {
    display: flex;
    flex-direction: column;

    @media (--rnb-mq-medium) {
        flex-direction: row;
    }
}

.listBlock {
    margin-right: 50px;
}

.listItem {
    display: flex;
    align-items: center;
    margin-bottom: var(--rnb-spacing-medium);

    &::before {
        content: '•';
        margin-right: var(--rnb-spacing-tiny);
        line-height: var(--rnb-font-size-mini);
        font-size: var(--rnb-font-size-mini);
    }
}
