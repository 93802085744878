.subHeader {
    --line-height__2: 1.4;
    --font-size: var(--ds-font-size-small);

    @media (--ds-mq-large) {
        --font-size: var(--ds-font-size-medium);
    }

    font-size: var(--font-size);
    font-weight: var(--ds-font-weight-light);
    font-family: var(--ds-font-family-ds-averta);
    line-height: var(--line-height__2);
}
